import axios from 'axios';

export const backendUrl = process.env.REACT_APP_BACKEND_URL;



export const getLinksData = async (username, password, outcome_ids, sports) => {
	try {
		const linksData = (
			await axios.post(`${backendUrl}/getLinks?username=${username}&password=${password}&sports=${sports}`, {
				outcome_id_normalized: outcome_ids,
			})
		).data;

		return linksData;
	} catch (e) {
		return [];
	}
};
