import { useEffect, useMemo, useState } from 'react';
import { EvScannerBar } from './EvScannerBar';
import { FiltersBar } from './FiltersBar/FiltersBar';
import { QueryBar } from './QueryBar';
import { getCommonData } from '../../api/getCommonData';
import { retrieveUsernameAndPassword } from '../../api/retrieveUsernameAndPassword';
import { runEvScan } from '../../api/runEvScan';
import { Table, TableHead, TableBody, TableCell, TableRow, Tooltip, Collapse, CircularProgress, Alert, TableSortLabel, Modal, Checkbox } from '@mui/material';
import { getRelatedOdds } from '../../api/getRelatedOdds';
import { GridLoader } from 'react-spinners';
import { getGamesData } from '../../api/getGamesData';
import { getLinksData } from '../../api/getLinksData';
import { savePreferences } from '../../api/saveSettings';
import { LogoGenerator } from '../LogoGenerator/LogoGenerator';
import LinkIcon from '@mui/icons-material/Link';
import './EvScanner.css';
import { NotificationsBar } from './FiltersBar/NotificationsBar';
import { Sidebar } from './Sidebar/Sidebar';
import { BetPrice } from '../BetPrice/BetPrice';
import { LineChart } from '@mui/x-charts';
import { animatedComponents } from './FiltersBar/QueryRow';
import Select from 'react-select';
import { NotificationsModal } from './NotificationsModal/NotificationsModal';
import { verifyDiscord } from '../../api/verifyDiscord';
import { toggleDiscordNotifications } from '../../api/toggleDiscordNotifications';
import { saveNotificationPreferences } from '../../api/saveNotificationSettings';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { arraysEqual } from '../../api/utils/arraysEqual';
import { ErrorBoundary } from 'react-error-boundary';
import { setBetPlaced } from '../../api/setBetPlaced';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { linkConverter } from '../../api/utils/linkConverter';

export const whiteShaded = '#F5F5F5';

export const white = '#FFFFFF';

export const grey = '#ADADAD';

export const lightGreen = '#31E000';

export const darkGreen = '#5ABE49';

export const topComparableSportsbooks = ['bet365', 'Pinnacle', 'FanDuel', 'Caesars'];

function descendingComparator(a, b, orderBy) {
	// console.log('a: ', a, orderBy, b[orderBy], a[orderBy], b[orderBy] < a[orderBy]);

	if (orderBy === 'start_date') {
		if (b.game?.[orderBy] < a.game?.[orderBy]) {
			return -1;
		}
		if (b.game?.[orderBy] > a.game?.[orderBy]) {
			return 1;
		}
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

const getLineMovementDirection = (historical_data) => {
	// console.log('historical_data: ', historical_data);
	try {
		const finalDataPoint = historical_data[historical_data.length - 1];
		const secondFinalDataPoint = historical_data[historical_data.length - 2];

		if (!secondFinalDataPoint || !finalDataPoint) {
			// no movement
			return 0;
		} else if (finalDataPoint.price > secondFinalDataPoint.price) {
			// direction is up
			return 1;
		} else if (finalDataPoint.price < secondFinalDataPoint.price) {
			// direction is down
			return -1;
		} else {
			// no movement
			return 0;
		}
	} catch (e) {
		// console.log(e, 'no movement');
		return 0;
	}
};

export function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export const formatPrice = (price) => {
	if (Number(price) > 0) {
		return <>&nbsp;+{price || ''}</>;
	} else {
		return `${price || ''}`;
	}
};

export const convertAmericanToImpliedProbability = (americanOdds) => {
	if (americanOdds > 0) {
		return 100 / (americanOdds + 100);
	} else {
		return -americanOdds / (-americanOdds + 100);
	}
};

export const convertImpliedProbabilityToAmerican = (impliedProbabilityOdds) => {
	if (impliedProbabilityOdds <= 50) {
		return 100 / (impliedProbabilityOdds / 100) - 100;
	} else {
		return -(impliedProbabilityOdds / (1 - impliedProbabilityOdds / 100));
	}
};

export const convertDecimalToAmerican = (decimalOdds) => {
	if (decimalOdds >= 2) {
		return (decimalOdds - 1) * 100;
	} else {
		return -100 / (decimalOdds - 1);
	}
};

export const convertAmericanToDecimal = (americanOdds) => {
	if (americanOdds > 0) {
		return 1 + americanOdds / 100;
	} else {
		return 1 - 100 / americanOdds;
	}
};

export const minutesSince = (date) => {
	//@ts-ignore
	const seconds = Math.floor((new Date() - date) / 1000);
	// const interval = ((seconds / 60) / 60).toFixed(1);
	const interval = seconds / 60;
	// return Math.floor(interval) + " minutes";
	return Math.floor(interval);
};

export const EvScanner = (props) => {
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [isNotificationFiltersOpen, setIsNotificationFiltersOpen] = useState(false);

	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

	const { username, password } = retrieveUsernameAndPassword();
	const [filterOptions, setFilterOptions] = useState();
	const [queryRows, setQueryRows] = useState([]);
	const [queryRowsNotifications, setQueryRowsNotifications] = useState([]);

	const [queries, setQueries] = useState([]);
	const [selectedQuery, setSelectedQuery] = useState();

	const [evData, setEvData] = useState([]);
	// console.log('evData: ', evData);

	const [isLoading, setIsLoading] = useState(false);
	const [isGamesLoading, setIsGamesLoading] = useState(false);

	const [selectedRow, setSelectedRow] = useState();
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [isModalLoading, setIsModalLoading] = useState(false);

	// const [isOddsHistoryOpen, setIsOddsHistoryOpen] = useState(false);
	const [selectedOddsHistorySportsbook, setSelectedOddsHistorySportsbook] = useState();
	const [selectedDumbBook, setSelectedDumbBook] = useState();

	const [selectedOddsHistoryXAxis, setSelectedOddsHistoryXAxis] = useState();
	const [selectedOddsHistoryYAxis, setSelectedOddsHistoryYAxis] = useState();
	const [selectedOddsHistoryXAxisUnder, setSelectedOddsHistoryXAxisUnder] = useState();
	const [selectedOddsHistoryYAxisUnder, setSelectedOddsHistoryYAxisUnder] = useState();
	const [selectedBooksIncludedInGraph, setSelectedBooksIncludedInGraph] = useState([]);
	const [selectedBooksIncludedInGraphToHide, setSelectedBooksIncludedInGraphToHide] = useState([]);

	const [checked, setChecked] = useState([]);

	const [showPlaceBets, setShowPlacedBets] = useState('all');

	const loginData = localStorage.getItem('loginData');
	const [bankroll, setBankroll] = useState(JSON.parse(loginData)?.bankroll || 50000);

	// console.log('loginData: ', JSON.parse(loginData));
	const betsplaced = [];
	try {
		for (const betplaced of JSON.parse(loginData)?.bets_placed) {
			if (betplaced.is_bet_placed === 'true') {
				betsplaced.push(betplaced.outcome_id_normalized);
			}
		}
	} catch (e) {}

	const [betsPlaced, setBetsPlaced] = useState(betsplaced);

	const [queryName, setQueryName] = useState(JSON.parse(loginData).queries?.[0]?.[0]?.name);

	const [isSaved, setIsSaved] = useState(false);

	const [isLiveFilter, setIsLiveFilter] = useState(false);

	const [discordUsername, setDiscordUsername] = useState(loginData?.discord_username);
	const [discordUsernameOriginal, setDiscordUsernameOriginal] = useState(loginData?.discord_username);

	const [isDiscordVerified, setIsDiscordVerified] = useState(loginData?.discord_verified);
	const [isDiscordActive, setIsDiscordActive] = useState(loginData?.discord_feeds_active);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('ev');
	const visibleRows = useMemo(() => evData?.sort(getComparator(order, orderBy), [evData, order, orderBy]).slice(0, 150), [evData, order, orderBy]);
	const [lineCharts, setLineCharts] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [betName, setBetName] = useState('');

	// useEffect(() => {
	// 	const id = setInterval(() => {
	// 		console.log('timer', new Date()) // TODO: this should auto refresh the ev scan
	// 	}, 5000);
	// 	return () => clearInterval(id);
	// })

	const scrollTo = () => {
		scroller.scrollTo('collapse-row', {
			duration: 700,
			delay: 0,
			smooth: 'easeInOutQuart',
			offset: -190,
		});
	};

	useEffect(() => {
		const getQueryMetadata = async (rows) => {
			// get list of possible sports, leagues, books and markets
			const data = await getCommonData(username, password);
			setFilterOptions(data);
			const loginData = localStorage.getItem('loginData');
			// console.log('loginData: ', JSON.parse(loginData));
			// console.log(JSON.parse(loginData).queries);
			setDiscordUsername(JSON.parse(loginData).discord_username);
			setDiscordUsernameOriginal(JSON.parse(loginData).discord_username_original);
			setIsDiscordVerified(JSON.parse(loginData).discord_verified);
			setIsDiscordActive(JSON.parse(loginData).discord_feeds_active);

			const initialQuery = rows ? rows : JSON.parse(loginData).queries[0];

			setIsLoading(true);
			const evDataRes = await runEvScan(username, password, initialQuery, isLiveFilter);
			setEvData(evDataRes);
			setIsLoading(false);
			setIsGamesLoading(true);
		};
		if (!filterOptions && !queryRows.length) {
			const loginData = localStorage.getItem('loginData');
			// console.log('🚀 ~ useEffect ~ loginData:', JSON.parse(loginData));

			if (!JSON.parse(loginData)?.feed_query?.length) {
				setQueryRowsNotifications([
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [1, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'Caesars', label: 'Caesars' },
							{ value: 'BetMGM', label: 'BetMGM' },
							{ value: 'FanDuel', label: 'FanDuel' },
						],
						sharp: [
							{ value: 'Pinnacle', label: 'Pinnacle' },
							{ value: 'Circa Vegas', label: 'Circa Vegas' },
						],
						market: [],
						marketType: [
							{ value: 'Main Markets', label: 'Main Markets' },
							{ value: 'Alternate Markets', label: 'Alternate Markets' },
						],
						name: 'Default EV Scan',
					},
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [3, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'BetMGM', label: 'BetMGM' },
						],
						sharp: [{ value: 'FanDuel', label: 'FanDuel' }],
						market: [],
						marketType: [{ value: 'Player Props', label: 'Player Props' }],
						name: 'Default EV Scan',
					},
				]);
			} else {
				setQueryRowsNotifications(JSON.parse(loginData).feed_query);
			}

			if (!JSON.parse(loginData)?.queries?.length) {
				setQueryRows([
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [1, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'Caesars', label: 'Caesars' },
							{ value: 'BetMGM', label: 'BetMGM' },
							{ value: 'FanDuel', label: 'FanDuel' },
						],
						sharp: [
							{ value: 'Pinnacle', label: 'Pinnacle' },
							{ value: 'Circa Vegas', label: 'Circa Vegas' },
						],
						market: [],
						marketType: [
							{ value: 'Main Markets', label: 'Main Markets' },
							{ value: 'Alternate Markets', label: 'Alternate Markets' },
						],
						name: 'Default EV Scan',
					},
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [3, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'BetMGM', label: 'BetMGM' },
						],
						sharp: [{ value: 'FanDuel', label: 'FanDuel' }],
						market: [],
						marketType: [{ value: 'Player Props', label: 'Player Props' }],
						name: 'Default EV Scan',
					},
				]);

				getQueryMetadata([
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [1, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'Caesars', label: 'Caesars' },
							{ value: 'BetMGM', label: 'BetMGM' },
							{ value: 'FanDuel', label: 'FanDuel' },
						],
						sharp: [
							{ value: 'Pinnacle', label: 'Pinnacle' },
							{ value: 'Circa Vegas', label: 'Circa Vegas' },
						],
						market: [],
						marketType: [
							{ value: 'Main Markets', label: 'Main Markets' },
							{ value: 'Alternate Markets', label: 'Alternate Markets' },
						],
						name: 'Default EV Scan',
					},
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-10000, 10000],
						minMaxEv: [3, 10000],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'BetMGM', label: 'BetMGM' },
						],
						sharp: [{ value: 'FanDuel', label: 'FanDuel' }],
						market: [],
						marketType: [{ value: 'Player Props', label: 'Player Props' }],
						name: 'Default EV Scan',
					},
				]);
			} else {
				// find the default query
				let defaultQuery;

				for (const query of JSON.parse(loginData).queries) {
					if (query.default) {
						defaultQuery = query;
					}
				}
				if (!defaultQuery) {
					defaultQuery = JSON.parse(loginData).queries[0];
				}

				setQueryRows(defaultQuery);
				setQueries(JSON.parse(loginData).queries);
				getQueryMetadata();
			}
		}
	});
	// console.log('query rows: ', queryRows);
	useEffect(() => {
		const getRelatedOddsData = async () => {
			if (selectedRow) {
				// @ts-ignore
				const outcome_id_normalized_no_book = selectedRow.outcome_id_normalized_no_book;
				const res = await getRelatedOdds(username, password, outcome_id_normalized_no_book, selectedRow.sport);

				if (showSidebar) {
					setShowSidebar(false);
					setSelectedRowData(res);
					setShowSidebar(true);
				} else {
					setSelectedRowData(res);
					setShowSidebar(true);
				}
				setSelectedDumbBook(selectedRow.sportsbook);
				// setIsModalOpen(true)
				setIsModalLoading(false);
			} else {
				setSelectedRowData([]);
			}
		};

		getRelatedOddsData();
	}, [selectedRow, username, password]);

	const getEvData = async () => {
		if (!isLoading) {
			setIsLoading(true);
			setIsGamesLoading(true);
			const evDataRes = await runEvScan(username, password, selectedQuery ? selectedQuery : queryRows, isLiveFilter);
			setEvData(evDataRes);
			setIsLoading(false);
		}
	};

	const setBetPlacedLocal = async (outcome_id_normalized, checked) => {
		await setBetPlaced(username, password, outcome_id_normalized, checked);
	};

	const saveSettings = async () => {
		await savePreferences(username, password, selectedQuery ? selectedQuery : queryRows, selectedQuery ? selectedQuery[0].name : queryName, bankroll);
		setIsSaved(true);
	};

	const saveNotificationSettings = async () => {
		await saveNotificationPreferences(username, password, selectedQuery ? selectedQuery : queryRowsNotifications, selectedQuery ? selectedQuery[0].name : queryName);
		setIsSaved(true);
	};

	const verifyDiscordLocal = async () => {
		await verifyDiscord(username, password, discordUsername);
	};

	useEffect(() => {
		const updateDiscordFeedActive = async () => {
			// console.log('isDiscordActive: ', isDiscordActive);
			await toggleDiscordNotifications(username, password, isDiscordActive);
			// const res = await axios.post(`${backendUrl}/preferences_feeds_active?discord_feeds_active=${isDiscordActive}&username=${username}&password=${password}`);
		};
		updateDiscordFeedActive();
	}, [isDiscordActive, username, password]);

	useEffect(() => {
		setTimeout(function () {
			setIsSaved(false);
		}, 5000);
	}, [isSaved]);

	useEffect(() => {
		// get game data and repopulate the evData variable
		const updateEvDataWithGamesAndLinks = async () => {
			// and update with limits
			const gameIds = [];
			const outcomeIds = [];
			const outcomeIdsSharps = [];
			const sports = []
			for (const play of evData) {
				gameIds.push(play.game_id);
				outcomeIds.push(play.outcome_id_normalized);
				const outcomeIdNormalizedArr = play.outcome_id_normalized.split(':');
				const outcomeIdNormalizedSharp = `${outcomeIdNormalizedArr[0]}:${outcomeIdNormalizedArr[1]}:${outcomeIdNormalizedArr[2]}:${
					outcomeIdNormalizedArr[3]
				}:${play.sportsbook_sharp?.split(', ')}`;
				outcomeIdsSharps.push(outcomeIdNormalizedSharp);

				if (!sports.includes(play.sport)) {
					sports.push(play.sport)
				}
			}

			const gamesRes = await getGamesData(username, password, gameIds);

			const evDataCopy = JSON.parse(JSON.stringify(evData));

			for (const play of evDataCopy) {
				play['game'] = gamesRes.games.find((game) => game.game_id === play.game_id);
			}
			setEvData(evDataCopy);

			const linksRes = await getLinksData(username, password, outcomeIds, sports);
			const linksResSharps = await getLinksData(username, password, outcomeIdsSharps, sports);

			for (const play of evDataCopy) {
				const outcomeIdNormalizedArr = play.outcome_id_normalized.split(':');
				const outcomeIdNormalizedSharp = `${outcomeIdNormalizedArr[0]}:${outcomeIdNormalizedArr[1]}:${outcomeIdNormalizedArr[2]}:${outcomeIdNormalizedArr[3]}:${
					play.sportsbook_sharp?.split(', ')[0]
				}`;
				const dumbdata = linksRes.find((game) => game.outcome_id_normalized === play.outcome_id_normalized);
				const sharpdata = linksResSharps.find((game) => game.outcome_id_normalized === outcomeIdNormalizedSharp);

				//
				// play['deep_link'] = dumbdata?.deep_link;
				// play['deep_link_sharp'] = sharpdata?.deep_link;
				play['limit'] = dumbdata?.limit;
				play['limit_sharp'] = sharpdata?.limit;
				if (play.ev_over > play.ev_under) {
					play['bet_price_direction'] = getLineMovementDirection(dumbdata?.bet_price_over_history);
					play['bet_price_direction_sharp'] = getLineMovementDirection(sharpdata?.bet_price_over_history);

					play['deep_link'] = dumbdata?.deep_link_over;
					play['deep_link_sharp'] = sharpdata?.deep_link_over;
				} else {
					play['bet_price_direction'] = getLineMovementDirection(dumbdata?.bet_price_under_history);
					play['bet_price_direction_sharp'] = getLineMovementDirection(sharpdata?.bet_price_under_history);
					play['deep_link'] = dumbdata?.deep_link_under;
					play['deep_link_sharp'] = sharpdata?.deep_link_under;
				}
			}
			setEvData(evDataCopy);
			// console.log("🚀 ~ updateEvDataWithGamesAndLinks ~ evDataCopy:", evDataCopy)

			setIsGamesLoading(false);
		};
		if (evData.length && isGamesLoading) {
			updateEvDataWithGamesAndLinks();
		}
	}, [evData]);

	// const [copyOfSelectedBooksIncludedInGraph, setCopyOfSelectedBooksIncludedInGraph] = useState([]);

	useEffect(() => {
		const getOddsHistory = async () => {
			// TODO: get the history for the selected sportsbook, then display

			//@ts-ignore
			if (selectedRow && (selectedRow?.sportsbook === selectedOddsHistorySportsbook || selectedOddsHistorySportsbook?.length > 1)) {
				// @ts-ignore
				const outcome_id_normalized_no_book = selectedRow.outcome_id_normalized_no_book;

				let selectedSportsbook = selectedRowData?.find((r) =>
					selectedOddsHistorySportsbook === 'FanDuel' ? r.sportsbook === 'Betway (Alaska)' : r.sportsbook === selectedOddsHistorySportsbook
				);

				if (!selectedSportsbook) {
					selectedSportsbook = selectedRowData;
				}

				// setIsModalOpen(true)
				// setIsModalLoading(false);

				if (selectedSportsbook?.length) {
					const xAxisValues = [];
					const yAxisValues = [];
					const xAxisValuesUnder = [];
					const yAxisValuesUnder = [];
					const booksIncludedInGraph = [];
					for (const book of selectedSportsbook) {
						const combinedComparableSportsbooks = [...topComparableSportsbooks, ...selectedBooksIncludedInGraph];
						let sportsbook = book.sportsbook;
						if (book.sportsbook === 'Betway (Alaska)') {
							sportsbook = 'FanDuel';
						}

						if (combinedComparableSportsbooks.includes(sportsbook) && !selectedBooksIncludedInGraphToHide.includes(sportsbook)) {
							const colour = getRandomColor();
							xAxisValues.push(
								book?.bet_price_over_history?.map((x) => {
									return minutesSince(new Date(x.time).getTime());
								})
							);
							yAxisValues.push(
								book?.bet_price_over_history?.map((x) => {
									return { price: x.price, book: book.sportsbook, colour: colour };
								})
							);
							xAxisValuesUnder.push(
								book?.bet_price_under_history?.map((x) => {
									return minutesSince(new Date(x.time).getTime());
								})
							);
							yAxisValuesUnder.push(
								book?.bet_price_under_history?.map((x) => {
									return { price: x.price, book: book.sportsbook, colour: colour };
								})
							);
							booksIncludedInGraph.push(book.sportsbook);
						}
					}
					setSelectedOddsHistoryXAxis(xAxisValues);
					setSelectedOddsHistoryYAxis(yAxisValues);
					setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
					setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
					// if (!arraysEqual(booksIncludedInGraph, selectedBooksIncludedInGraph)) {
					// TODO: REVISIT THIS LOGIC! IT WAS CAUSING INFINITE RERENDERS BUT IS NEEDED TO REMOVE DUPLICATE SPORTSBOOKS
					// 	function uniq(a) {
					// 		var prims = { boolean: {}, number: {}, string: {} },
					// 			objs = [];

					// 		return a.filter(function (item) {
					// 			var type = typeof item;
					// 			if (type in prims) return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
					// 			else return objs.indexOf(item) >= 0 ? false : objs.push(item);
					// 		});
					// 	}
					// 	const selectedBooks = uniq([...booksIncludedInGraph, selectedRow?.sportsbook_dumb, selectedRow?.sportsbook_sharp]);

					// 	// setSelectedBooksIncludedInGraph(selectedBooks);
					// }
				} else {
					// @ts-ignore
					const xAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
						return minutesSince(new Date(x.time).getTime());
					});
					// @ts-ignore
					const yAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
						return x.price;
					});
					setSelectedOddsHistoryXAxis(xAxisValues);
					setSelectedOddsHistoryYAxis(yAxisValues);
					// @ts-ignore
					const xAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
						return minutesSince(new Date(x.time).getTime());
					});
					// @ts-ignore
					const yAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
						return x.price;
					});
					setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
					setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
				}
			} else if (selectedRow && selectedOddsHistorySportsbook && selectedRowData) {
				const selectedSportsbook = selectedRowData.find((r) => r.sportsbook === selectedOddsHistorySportsbook);

				// @ts-ignore
				const xAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
					return minutesSince(new Date(x.time).getTime());
				});
				// @ts-ignore
				const yAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
					return x.price;
				});
				setSelectedOddsHistoryXAxis(xAxisValues);
				setSelectedOddsHistoryYAxis(yAxisValues);

				// @ts-ignore
				const xAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
					return minutesSince(new Date(x.time).getTime());
				});

				// @ts-ignore
				const yAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
					return x.price;
				});
				setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
				setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
			}
		};

		// if (selectedBooksIncludedInGraph !== copyOfSelectedBooksIncludedInGraph || typeof selectedOddsHistorySportsbook === 'string') {
		// getOddsHistory();
		// }

		if (lineCharts && selectedRowData) {
			getOddsHistory();
		}
	}, [selectedOddsHistorySportsbook, selectedRow, selectedBooksIncludedInGraph, lineCharts, selectedRowData]);

	const allSportsbooks = selectedRowData.map((e) => {
		return {
			value: e.sportsbook,
			label: e.sportsbook,
		};
	});
	const selectedOddsHistoryYAxisUnderMapped = [];
	const selectedOddsHistoryYAxisMapped = [];
	function getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	try {
		for (const y of selectedOddsHistoryYAxisUnder) {
			// selectedOddsHistoryYAxisUnderMapped.push(y?.map((e) => convertDecimalToAmerican(e).toFixed(0)));
			selectedOddsHistoryYAxisUnderMapped.push(y);
		}
		for (const y of selectedOddsHistoryYAxis) {
			// selectedOddsHistoryYAxisMapped.push(y?.map((e) => convertDecimalToAmerican(e).toFixed(0)));
			selectedOddsHistoryYAxisMapped.push(y);
		}
	} catch (e) {}

	const xAxes = selectedOddsHistoryXAxis
		?.sort((a, b) => b.length - a.length)
		.map((e) => {
			try {
				return {
					data: [...e]?.reverse(),
					label: 'Minutes ago',
					valueFormatter: (value, context) => {
						if (context.location === 'tooltip') {
							return `${value.toString()} mins ago`;
						} else return value.toString();
					},
					reverse: true,
				};
			} catch (e) {
				return {
					data: [].reverse(),
					color: '#FFFFF',
					label: ``,
					valueFormatter: (value) => (value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null),
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			}
		});

	const xAxesUnder = selectedOddsHistoryXAxisUnder
		?.sort((a, b) => b.length - a.length)
		.map((e) => {
			try {
				return {
					data: [...e]?.reverse(),
					label: 'Minutes ago',
					valueFormatter: (value, context) => {
						if (context.location === 'tooltip') {
							return `${value.toString()} mins ago`;
						} else return value.toString();
					},
					reverse: true,
				};
			} catch (e) {
				return {
					data: [].reverse(),
					color: '#FFFFF',
					label: ``,
					valueFormatter: (value) => (value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null),
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			}
		});

	const yAxes = selectedOddsHistoryYAxisMapped
		?.sort((a, b) => b.length - a.length)
		.map((e, i) => {
			try {
				const data = e?.map((t) => t.price);
				const book = e?.[0].book === 'Betway (Alaska)' ? 'FanDuel' : e?.[0].book;
				const colour = e?.[0].colour;

				return {
					data: [...data].reverse(),
					color: colour,
					label: `${book}`,
					valueFormatter: (value) => {
						return value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null;
					},
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			} catch (e) {
				return {
					data: [].reverse(),
					color: '#FFFFF',
					label: ``,
					valueFormatter: (value) => (value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null),
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			}
		});

	const yAxesUnder = selectedOddsHistoryYAxisUnderMapped
		?.sort((a, b) => b.length - a.length)
		.map((e, i) => {
			try {
				const data = e?.map((t) => t.price);
				const book = e?.[0].book === 'Betway (Alaska)' ? 'FanDuel' : e?.[0].book;
				const colour = e?.[0].colour;
				return {
					data: [...data].reverse(),
					color: colour,
					label: `${book}`,
					valueFormatter: (value) => (value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null),
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			} catch (e) {
				return {
					data: [].reverse(),
					color: '#FFFFF',
					label: ``,
					valueFormatter: (value) => (value !== null ? convertDecimalToAmerican(value).toFixed(0).toString() : null),
					curve: 'linear',
					showMark: false,
					reverse: true,
				};
			}
		});

	return (
		<div style={{ backgroundColor: '#485B79', height: evData.length ? '100%' : '100vh', color: 'white' }}>
			<EvScannerBar bankroll={bankroll} setBankroll={setBankroll} />
			<QueryBar
				getEvData={getEvData}
				setIsFiltersOpen={setIsFiltersOpen}
				isFiltersOpen={isFiltersOpen || isNotificationFiltersOpen}
				isNotificationsOpen={isNotificationsOpen}
				setIsNotificationsOpen={setIsNotificationsOpen}
				queryName={queryName}
				setQueryName={setQueryName}
				queryRows={queryRows}
				setQueryRows={setQueryRows}
				isLiveFilter={isLiveFilter}
				setIsLiveFilter={setIsLiveFilter}
				queries={queries}
				setQueries={setQueries}
				setSelectedQuery={setSelectedQuery}
				selectedQuery={selectedQuery}
			/>
			{isSaved && (
				<div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '-2rem' }}>
					<Alert severity="success">Settings saved!</Alert>
				</div>
			)}
			{isFiltersOpen && filterOptions && (
				<FiltersBar
					filterOptions={filterOptions}
					isOpen={isFiltersOpen}
					setIsOpen={setIsFiltersOpen}
					queryRows={queryRows}
					selectedQuery={selectedQuery}
					setQueryRows={setQueryRows}
					saveSettings={saveSettings}
					setSelectedQuery={setSelectedQuery}
					setIsNotificationFiltersOpen={setIsNotificationFiltersOpen}
					setIsFiltersOpen={setIsFiltersOpen}
				/>
			)}
			<Modal
				open={isNotificationsOpen}
				onClose={() => {
					setIsNotificationsOpen(false);
				}}>
				<div>
					<NotificationsModal
						verifyDiscordLocal={verifyDiscordLocal}
						setDiscordUsername={setDiscordUsername}
						setDiscordUsernameOriginal={setDiscordUsernameOriginal}
						discordUsername={discordUsername}
						discordUsernameOriginal={discordUsernameOriginal}
						isDiscordVerified={isDiscordVerified}
						isDiscordActive={isDiscordActive}
						setIsDiscordActive={setIsDiscordActive}
					/>
				</div>
			</Modal>
			{isNotificationFiltersOpen && filterOptions && (
				<NotificationsBar
					filterOptions={filterOptions}
					isOpen={isFiltersOpen}
					setIsOpen={setIsFiltersOpen}
					queryRows={queryRowsNotifications}
					setQueryRows={setQueryRowsNotifications}
					saveSettings={saveSettings}
					discordUsername={discordUsername}
					setDiscordUsername={setDiscordUsername}
					isDiscordVerified={isDiscordVerified}
					setIsDiscordVerified={setIsDiscordVerified}
					isDiscordActive={isDiscordActive}
					setIsDiscordActive={setIsDiscordActive}
					setIsNotificationFiltersOpen={setIsNotificationFiltersOpen}
					setIsFiltersOpen={setIsFiltersOpen}
					saveNotificationSettings={saveNotificationSettings}
				/>
			)}
			<div style={{ display: 'flex' }}>
				<div style={{ marginTop: '2rem', marginLeft: '2rem', marginRight: '2rem', width: '100%' }}>
					<ToggleSwitch values={['unplaced', 'all', 'placed']} setSelected={setShowPlacedBets} selected={showPlaceBets} />

					<Table>
						<TableHead style={{ backgroundColor: '#0f1b33' }}>
							<TableRow>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', borderTopLeftRadius: '12px' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'market' ? order : 'asc'}
										onClick={() => {
											setOrderBy('market');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Market
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'bet_name' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_name');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Bet
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', maxWidth: '50px' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Book
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }} sortDirection="asc">
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'bet_price' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Price
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sharps' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sharps');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Sharp
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sharp_prices' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sharp_prices');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Sharp Price
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									Game
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'start_date' ? order : 'asc'}
										onClick={() => {
											setOrderBy('start_date');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Starts
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'ev' ? order : 'asc'}
										onClick={() => {
											setOrderBy('ev');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										EV
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'ev' ? order : 'asc'}
										onClick={() => {
											setOrderBy('quarter_kelly');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Quarter Kelly
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', borderTopRightRadius: '12px', maxWidth: '50px' }}>
									Bet Placed?
									<br />
								</TableCell>
							</TableRow>
						</TableHead>
						{!isLoading && (
							<TableBody>
								{visibleRows?.map((row, index) => {
									let deepLink = linkConverter(row.deep_link?.desktop?.replace(/<STATE>/g, 'on'));
									let deepLinkSharp = linkConverter(row.deep_link_sharp?.desktop?.replace(/<STATE>/g, 'on'));

									// use a state variable: betsPlaced
									// stores an array of all the bets placed
									// starts by getting populated by the logindata
									// then gets updated when the user clicks on any checkboxes
									// instead of using the straight login data, use the state variable
									let isBetPlacedChecked = false;
									for (const betplaced of betsPlaced) {
										if (betplaced === row.outcome_id_normalized) {
											isBetPlacedChecked = true;
										}
									}

									let backgroundColor = index % 2 === 0 ? whiteShaded : '#e6e7ec';
									if (isBetPlacedChecked) {
										backgroundColor = '#9296a1';
									}

									const table = (
										<>
											<TableRow
												style={{ backgroundColor: backgroundColor }}
												className="cursor-pointer"
												key={'temp'}
												onClick={() => {
													setShowSidebar(false);
													setSelectedRowData([])
													setIsModalLoading(true);
													// @ts-ignore
													if (selectedRow?.id === row.id) {
														setSelectedRow(undefined);
														setLineCharts(false);
													} else {
														setSelectedRow(row);
														setSelectedOddsHistorySportsbook(row.sportsbook);
														setSelectedBooksIncludedInGraphToHide([]);
														setLineCharts(true);
														setBetName(row?.bet_name);
													}
													setShowSidebar(true)
													// if (showSidebar) {
													// 	setShowSidebar(true);
													// } else {
													// 	setShowSidebar(true);
													// }
												}}
												sx={{
													'&:hover': {
														backgroundColor: '#cbcbcb !important',
													},
												}}>
												<TableCell className="text-black">{row.bet_type}</TableCell>
												<TableCell className="text-black">{row.bet_name}</TableCell>
												<TableCell className="text-black">
													{/* logo generator, input is sportsbook */}
													<LogoGenerator sportsbook={row.sportsbook_dumb} />
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && deepLink && (
														<>
															<a
																target="_new"
																href={deepLink}
																style={{
																	textDecoration: 'none',
																	color: '#1a0540',
																	display: 'block',
																	width: 'min-content',
																}}>
																<div
																	style={{
																		backgroundColor: '#dd8d13',
																		width: 'fit-content',
																		padding: '4px',
																		borderRadius: '8px',
																		opacity: 0.85,
																	}}
																	className="bet-button">
																	<div style={{ display: 'flex', flexDirection: 'row' }}>
																		<LinkIcon style={{ width: '15px', textAlign: 'center' }} />
																		<span style={{ alignSelf: 'center', marginLeft: '2px' }}>Bet!</span>
																	</div>
																</div>
															</a>
														</>
													)}
												</TableCell>
												<TableCell className="text-black">
													<div className="flex flex-row">
														<BetPrice price={row.bet_price} direction={row.bet_price_direction} limit={row.limit} />
													</div>
												</TableCell>
												<TableCell className="text-black" width={50}>
													{/* logo generator, input is sportsbook */}
													<div style={{ display: 'grid', gridTemplateColumns: 'auto auto', width: 'min-content' }}>
														{row.sportsbook_sharp?.split(',').map((e) => {
															return <LogoGenerator sportsbook={e} />;
														})}
													</div>

													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && deepLinkSharp && (
														<a
															target="_new"
															href={deepLinkSharp}
															style={{
																textDecoration: 'none',
																color: '#1a0540',
																display: 'block',
																width: 'min-content',
															}}>
															<div
																style={{
																	backgroundColor: '#dd8d13',
																	width: 'fit-content',
																	padding: '4px',
																	borderRadius: '8px',
																	opacity: 0.85,
																}}
																className="bet-button">
																<div style={{ display: 'flex', flexDirection: 'row' }}>
																	<LinkIcon style={{ width: '15px', textAlign: 'center' }} />
																	<span style={{ alignSelf: 'center', marginLeft: '2px' }}>Bet!</span>
																</div>
															</div>
														</a>
													)}
													
												</TableCell>
												<TableCell className="text-black">
												<Tooltip title={row.ev_over > 0 ? `Fair value: ${Math.abs(convertImpliedProbabilityToAmerican(row.devigged1 * 100).toFixed(0))}` : `Fair value: ${Math.abs(convertImpliedProbabilityToAmerican(row.devigged2 * 100).toFixed(0))}`}>
													<div className="flex flex-row">
														<p className="mt-3">
															<BetPrice price={row.sharp_prices} direction={row.bet_price_direction_sharp} limit={row.limit_sharp} />
														</p>			
													</div>
													</Tooltip>
												</TableCell>
												<TableCell className="text-black">
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.game && (
														<>
															{row.game?.away_team_display} @ {row.game?.home_team_display}
														</>
													)}
												</TableCell>
												<TableCell className="text-black">
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.game && (
														<>
															{new Date(row?.game?.start_date).toDateString()} @ {new Date(row?.game?.start_date).toLocaleTimeString('en-us')}
														</>
													)}
												</TableCell>
												<TableCell className="text-black">{(Number(row.ev) * 100).toFixed(2)}%</TableCell>
												<TableCell className="text-black">
													<Tooltip title={`$${(row.quarter_kelly?.toFixed(3) * (bankroll / 100)).toFixed(1)}`}>
														<div>{Number(row.quarter_kelly).toFixed(2)}u</div>
													</Tooltip>
												</TableCell>
												<TableCell
													onClick={(e) => {
														e.stopPropagation();
														setShowSidebar(false);

														if (e.target.checked) {
															setChecked([...checked, row.outcome_id_normalized]);
															setBetsPlaced([...betsPlaced, row.outcome_id_normalized]);
														} else {
															const checkedCopy = JSON.parse(JSON.stringify(checked));
															const temp = [];
															for (const chk of checkedCopy) {
																if (chk !== row.outcome_id_normalized) {
																	temp.push(chk);
																}
															}
															setChecked(temp);

															const temp2 = [];
															for (const t of betsPlaced) {
																if (t !== row.outcome_id_normalized) {
																	temp2.push(t);
																}
															}
															setBetsPlaced(temp2);
														}
														setBetPlacedLocal(row.outcome_id_normalized, e.target.checked);
													}}>
													<Tooltip title="Bet placed">
														<Checkbox checked={isBetPlacedChecked || checked?.includes(row?.outcome_id_normalized)} />
													</Tooltip>
												</TableCell>
											</TableRow>
											{lineCharts &&
											selectedRowData.length &&
											(selectedOddsHistoryYAxis || selectedOddsHistoryYAxisUnder) &&
											selectedRow?.id === row.id &&
											typeof selectedOddsHistoryXAxis?.[0] !== 'object' ? (
												<TableRow>
													<TableCell colSpan={12} sx={{ padding: '0px !important' }}>
														<Collapse in={true} className="collapse-row" unmountOnExit>
															<div style={{ backgroundColor: index % 2 === 0 ? whiteShaded : '#e6e7ec', overflowX: 'auto', textAlign: 'center', overflowY: 'hidden' }}>
																<div
																	style={{
																		display: 'flex',
																		// backgroundColor: 'white',
																		// marginTop: '10vh',
																		// marginLeft: '4rem',
																		// marginRight: '4rem',
																		borderRadius: '10px',
																		paddingTop: '10px',
																	}}>
																	<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
																		<div style={{ display: 'flex' }}>
																			<LogoGenerator sportsbook={selectedOddsHistorySportsbook} />
																			<div style={{ marginLeft: '1rem' }}>
																				{selectedRowData[0].selection} over {selectedRowData[0].selection_points} {selectedRowData[0].market}
																			</div>
																		</div>
																		<ErrorBoundary fallback={<div>graph</div>}>
																			<LineChart
																				xAxis={[
																					{
																						// @ts-ignore
																						data: selectedOddsHistoryXAxis ? [...selectedOddsHistoryXAxis].reverse() : [],
																						label: 'Minutes ago',
																						valueFormatter: (value, context) => {
																							if (context.location === 'tooltip') {
																								return `${value.toString()} mins ago`;
																							} else return value.toString();
																						},
																						reverse: true,
																						// hideTooltip: true,
																					},
																				]}
																				series={[
																					{
																						data: [...selectedOddsHistoryYAxis].reverse() || [],
																						color: '#0f1b33',
																						label: 'Price',
																						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
																						curve: 'linear',
																						showMark: false,
																						// reverse: true
																					},
																				]}
																				axisHighlight={{ x: 'line', y: 'none' }}
																				tooltip={{ trigger: 'axis' }}
																				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																				width={400}
																				height={300}
																				slotProps={{ legend: { hidden: true } }}
																				sx={{
																					'& .MuiChartsAxis-label': {
																						// padding: "10px !important",
																						// fill: "white !important",
																						marginRight: '20px',
																					},
																					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																						transform: 'translateX(-12px) !important',
																					},
																				}}
																			/>
																		</ErrorBoundary>
																	</div>
																	<div style={{ width: '40%', borderRadius: '10px' }}>
																		<div style={{ display: 'flex' }}>
																			<div
																				style={{
																					marginRight: '1rem',
																				}}>
																				<LogoGenerator sportsbook={selectedOddsHistorySportsbook} />
																			</div>
																			{selectedRowData[0].selection} under {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>
																		<ErrorBoundary fallback={<div>graph 2</div>}>
																			<LineChart
																				xAxis={[
																					{
																						// @ts-ignore
																						data: selectedOddsHistoryXAxisUnder ? [...selectedOddsHistoryXAxisUnder].reverse() : [],
																						label: 'Minutes ago',
																						valueFormatter: (value, context) => {
																							if (context.location === 'tooltip') {
																								return `${value.toString()} mins ago`;
																							} else return value.toString();
																						},
																						reverse: true,
																						// hideTooltip: true,
																					},
																				]}
																				series={[
																					{
																						data: [...selectedOddsHistoryYAxisUnder].reverse() || [],
																						color: '#0f1b33',
																						label: 'Price',
																						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
																						curve: 'linear',
																						showMark: false,
																						// reverse: true
																					},
																				]}
																				axisHighlight={{ x: 'line', y: 'none' }}
																				tooltip={{ trigger: 'axis' }}
																				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																				width={400}
																				height={300}
																				slotProps={{ legend: { hidden: true } }}
																				sx={{
																					'& .MuiChartsAxis-label': {
																						// padding: "10px !important",
																						// fill: "white !important",
																						marginRight: '20px',
																					},
																					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																						transform: 'translateX(-12px) !important',
																					},
																				}}
																			/>
																		</ErrorBoundary>
																	</div>
																</div>
															</div>
														</Collapse>
													</TableCell>
												</TableRow>
											) : (
												<></>
											)}
											{lineCharts &&
											selectedRowData.length &&
											(selectedOddsHistoryYAxis || selectedOddsHistoryYAxisUnder) &&
											selectedRow?.id === row.id &&
											typeof selectedOddsHistoryXAxis?.[0] === 'object' ? (
												<TableRow>
													<TableCell colSpan={12} sx={{ padding: '0px !important' }}>
														<Collapse className="collapse-row" in={true} unmountOnExit>
															<div style={{ backgroundColor: index % 2 === 0 ? whiteShaded : '#e6e7ec', overflowX: 'auto', textAlign: 'center', overflowY: 'hidden' }}>
																<div
																	style={{
																		display: 'flex',
																		backgroundColor: 'white',
																		// marginTop: '10vh',
																		// marginLeft: '4rem',
																		// marginRight: '4rem',
																		borderRadius: '10px',
																		// paddingTop: '10px',
																	}}>
																	<div style={{ maxWidth: '190px', marginLeft: '1rem', textAlign: 'left' }}>
																		Select books
																		<Select
																			options={allSportsbooks}
																			isMulti
																			components={animatedComponents}
																			placeholder="sportsbooks"
																			onChange={(e) => {
																				const sportsbooks = e.map((y) => y.value);

																				const copy = [...new Set(JSON.parse(JSON.stringify([...topComparableSportsbooks, ...sportsbooks])))];

																				setSelectedBooksIncludedInGraph(copy);
																			}}
																		/>
																	</div>
																	<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
																		<div style={{ marginLeft: '1rem' }}>
																			{selectedRowData[0].selection} over {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>

																		<div style={{ display: 'flex', marginTop: '1rem' }}>
																			{selectedBooksIncludedInGraph
																				.filter((x) => !selectedBooksIncludedInGraphToHide?.includes(x))
																				?.map((e, i) => {
																					const matchingColour = yAxesUnder?.find((y) => y?.label === e)?.color;
																					return (
																						<div
																							style={{ marginRight: '8px', cursor: 'pointer' }}
																							onClick={() => {
																								setSelectedBooksIncludedInGraphToHide([...selectedBooksIncludedInGraphToHide, e]);
																							}}>
																							<LogoGenerator sportsbook={e} />
																							<div style={{ height: '2px', backgroundColor: matchingColour }} />
																						</div>
																					);
																				})}
																		</div>
																		<ErrorBoundary fallback={<div>graph 2</div>}>
																			<LineChart
																				xAxis={xAxes}
																				series={yAxes}
																				axisHighlight={{ x: 'line', y: 'none' }}
																				tooltip={{ trigger: 'axis' }}
																				yAxis={[
																					{
																						label: 'Price',
																						valueFormatter: (value) => {
																							if (convertDecimalToAmerican(value).toFixed(0) > -10000 && convertDecimalToAmerican(value).toFixed(0) < 10000) {
																								return convertDecimalToAmerican(value).toFixed(0);
																							} else return 0;
																						},
																					},
																				]}
																				width={400}
																				height={300}
																				slotProps={{
																					legend: { hidden: true },
																				}}
																				sx={{
																					'& .MuiChartsAxis-label': {
																						// padding: "10px !important",
																						// fill: "white !important",
																						marginRight: '20px',
																					},
																					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																						transform: 'translateX(-12px) !important',
																					},
																				}}
																			/>
																		</ErrorBoundary>
																	</div>
																	<div style={{ backgroundColor: 'white', width: '40%', borderRadius: '10px' }}>
																		<div style={{ marginLeft: '1rem' }}>
																			{selectedRowData[0].selection} under {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>

																		<div style={{ display: 'flex', marginTop: '1rem' }}>
																			{selectedBooksIncludedInGraph?.map((e, i) => {
																				const matchingColour = yAxesUnder?.find((y) => y?.label === e)?.color;

																				return (
																					<div
																						style={{ marginRight: '8px', cursor: 'pointer' }}
																						onClick={() => {
																							setSelectedBooksIncludedInGraphToHide([...selectedBooksIncludedInGraphToHide, e]);
																						}}>
																						<LogoGenerator sportsbook={e} />
																						<div style={{ height: '2px', backgroundColor: matchingColour }} />
																					</div>
																				);
																			})}
																		</div>
																		<ErrorBoundary fallback={<div>graph 4</div>}>
																			<LineChart
																				// xAxis={[{ data: [1249, 1204, 1161, 1068, 1034, 922, 484, 479, 478, 475, 475, 471, 470, 420, 419, 398, 398, 295, 241, 238, 237, 236, 189, 114, 113]
																				// 	.reverse() }]}
																				// series={[
																				// 	{
																				// 	  data: [3.9, 3.65, 3.6, 3.65, 3.7, 3.65, 3.7, 3.65, 3.7, 3.65, 3.6, 3.65, 3.6, 3.65, 3.6, 3.65, 3.7, 3.65, 3.7, 3.65, 3.9, 3.8, 3.65, 3.6, 3.6]
																				// 	  ,
																				// 	},
																				//   ]}
																				xAxis={xAxesUnder}
																				series={yAxesUnder}
																				axisHighlight={{ x: 'line', y: 'none' }}
																				tooltip={{ trigger: 'axis' }}
																				yAxis={[
																					{
																						label: 'Price',
																						valueFormatter: (value) => {
																							if (convertDecimalToAmerican(value).toFixed(0) > -10000 && convertDecimalToAmerican(value).toFixed(0) < 10000) {
																								return convertDecimalToAmerican(value).toFixed(0);
																							} else {
																								return 0;
																							}
																						},
																					},
																				]}
																				width={400}
																				height={300}
																				slotProps={{
																					legend: { hidden: true },
																				}}
																				sx={{
																					'& .MuiChartsAxis-label': {
																						// padding: "10px !important",
																						// fill: "white !important",
																						marginRight: '20px',
																					},
																					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																						transform: 'translateX(-12px) !important',
																					},
																				}}
																			/>
																		</ErrorBoundary>
																	</div>
																</div>
															</div>
														</Collapse>
													</TableCell>
												</TableRow>
											) : (
												<></>
											)}
										</>
									);

									if (showPlaceBets === 'placed') {
										if (isBetPlacedChecked || checked?.includes(row?.outcome_id_normalized)) {
											// if checked
											return table;
										} else return null;
									} else if (showPlaceBets === 'unplaced') {
										if (!isBetPlacedChecked && !checked?.includes(row?.outcome_id_normalized)) {
											// if not checked
											return table;
										} else {
											return null;
										}
									} else {
										return table;
									}
								})}
							</TableBody>
						)}
					</Table>
					{isLoading && (
						<div style={{ textAlign: 'center', marginTop: '4rem', height: '100vh', position: 'sticky', top: 0 }}>
							<GridLoader />
						</div>
					)}
				</div>
				{selectedRowData.length && showSidebar ? (
					<div style={{ marginTop: '3.5rem', marginRight: '2rem', width: '100%' }}>
						<Sidebar
							data={selectedRowData}
							oddsHistoryData={{ selectedOddsHistoryXAxis, selectedOddsHistoryYAxis, selectedOddsHistoryXAxisUnder, selectedOddsHistoryYAxisUnder }}
							setSelectedOddsHistorySportsbook={setSelectedOddsHistorySportsbook}
							selectedOddsHistorySportsbook={selectedOddsHistorySportsbook}
							selectedBooksIncludedInGraph={selectedBooksIncludedInGraph}
							setSelectedBooksIncludedInGraph={setSelectedBooksIncludedInGraph}
							selectedDumbBook={selectedDumbBook}
							lineCharts={lineCharts}
							setLineCharts={setLineCharts}
							scrollTo={scrollTo}
							sharpPrice={selectedRow?.selected_sharp_price?.toFixed(0)}
							dumbPrice={selectedRow?.bet_price}
							sharpBook={selectedRow?.sportsbook_sharp}
							dumbBook={selectedRow?.sportsbook_dumb}
							betName={betName}
							// side={}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
