import { TextField, Tooltip } from '@mui/material';
import play_icon from '../../play_icon.svg';
import './QueryBar.css';
import { useEffect, useState } from 'react';
import filtersIcon from '../../filters_icon.svg';
import notificationsicon from '../../notifications_icon.svg';
import { CustomDropdown } from './CustomDropdown/CustomDropdown';
import EditIcon from '@mui/icons-material/Edit';
// import Dropdown from 'react-bootstrap/Dropdown';
// import 'bootstrap/dist/css/bootstrap.min.css';

const isLiveStyle = {
	backgroundColor: '#0f1b33',
	color: 'white',
	padding: '8px',
	borderRadius: '8px',
};

export const QueryBar = (props) => {
	const { queries, setQueries, selectedQuery, setSelectedQuery } = props;
	const [renameQueryOpen, setRenameQueryOpen] = useState(false);

	return (
		<div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F1F1F4', borderRadius: '12px', marginLeft: '2rem', marginRight: '2rem', color: '#0E1B33' }}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div style={{ display: 'flex', flexDirection: 'row', marginLeft: '1rem', marginTop: '8px' }}>
					<CustomDropdown firstQuery={selectedQuery ? selectedQuery[0].name : props.queryRows[0]?.name || 'Default EV Scan'} queries={queries} setQueries={setQueries} selectedQuery={selectedQuery} setSelectedQuery={setSelectedQuery} />
					{!renameQueryOpen && (
						<Tooltip title="Click to rename query">
							<p
								onClick={() => {
									setRenameQueryOpen(true);
								}}
								style={{ marginLeft: '1rem', cursor: 'pointer' }}>
								{/* {selectedQuery ? selectedQuery[0].name : props.queryName} */}
								<span> <EditIcon /> </span>
							</p>
						</Tooltip>
					)}
					{renameQueryOpen && (
						<TextField
							style={{ marginTop: '1rem', marginLeft: '1rem' }}
							variant="standard"
							onClick={() => {
								// setRenameQueryOpen(false)
							}}
							focused={true}
							value={selectedQuery ? selectedQuery[0].name : props.queryName}
							onChange={(e) => {
								// const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								// queryRowsCopy[0].name = e.target.value;
								if (selectedQuery) {
									props.setQueryName(e.target.value)
									const selectedQueryCopy = JSON.parse(JSON.stringify(selectedQuery))
									for (const x of selectedQueryCopy) {
										x.name = e.target.value
									}
									setSelectedQuery(selectedQueryCopy)
								} else {
									props.setQueryName(e.target.value);
								}
							}}
							onBlur={() => {
								setRenameQueryOpen(false);
							}}
							inputRef={(input) => input && input.focus()}
						/>
					)}
				</div>

				<Tooltip title="Run EV Scan">
					<img
						className="run-button"
						alt="run query icon"
						style={{ marginLeft: '1rem', width: '30px', cursor: 'pointer' }}
						src={play_icon}
						onClick={async () => {
							await props.getEvData();
						}}
					/>
				</Tooltip>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginLeft: 'auto',
					marginRight: 'auto',
					alignItems: 'center',
				}}>
				<div style={{ padding: '5px', cursor: 'pointer' }}>
					<span
						style={
							!props.isLiveFilter
								? isLiveStyle
								: {
										padding: '8px',
										borderRadius: '8px',
								  }
						}
						className="toggle-button"
						onClick={() => {
							props.setIsLiveFilter(false);
						}}>
						Pregame
					</span>
				</div>
				<div style={{ padding: '5px', cursor: 'pointer' }}>
					<span
						style={
							props.isLiveFilter
								? isLiveStyle
								: {
										padding: '8px',
										borderRadius: '8px',
								  }
						}
						className="toggle-button"
						onClick={() => {
							props.setIsLiveFilter(true);
						}}>
						Live
					</span>
				</div>
			</div>

			<div
				onClick={() => {
					props.setIsNotificationsOpen(false);
					props.setIsFiltersOpen(!props.isFiltersOpen);
				}}
				style={{
					marginLeft: 'auto',
					marginRight: '1rem',
					cursor: 'pointer',
					// marginTop: 'auto',
					// marginBottom: 'auto',
					display: 'flex',
					flexDirection: 'row',
					paddingRight: "4px",
					backgroundColor: props.isFiltersOpen ? '#b7bac5' : '',
				}}
				className="run-button">
				<img src={filtersIcon} />
				<div style={{ alignContent: 'center' }}><span style={{paddingRight: "10px"}}>Filters</span></div>
			</div>
			<div
				className="run-button"
				style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center', paddingTop: '4px', paddingBottom: '4px' }}
				onClick={() => {
					props.setIsFiltersOpen(false);
					props.setIsNotificationsOpen(!props.isNotificationsOpen);
				}}>
				<img width={35} height={35} src={notificationsicon} />
				<p style={{ marginRight: '1rem', marginTop: 'auto', marginBottom: 'auto' }}>Notifications</p>
			</div>
		</div>
	);
};
