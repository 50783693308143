import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const getCommonData = async (username, password) => {
    const books = (await axios.get(`${backendUrl}/getBooks?username=${username}&password=${password}`)).data;
    const sports = (await axios.get(`${backendUrl}/getSports?username=${username}&password=${password}`)).data;
    const leagues = (await axios.get(`${backendUrl}/getLeagues?username=${username}&password=${password}`)).data;
    const markets = (await axios.get(`${backendUrl}/getMarkets?username=${username}&password=${password}`)).data;

    // const markets = 
    // console.log("🚀 ~ getCommonData ~ books:", books, sports)
    
    return {books, sports, leagues, markets};
}