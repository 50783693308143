import { useState } from 'react';
import './CustomDropdown.css';
import Select from 'react-select/creatable';

export const CustomDropdown = (props) => {
	const { firstQuery, queries, setQueries, setSelectedQuery, selectedQuery } = props;

	const queryNames = queries?.map((q) => ({value: q?.[0]?.name, label: q?.[0]?.name}));

	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			style={{ borderRadius: '12px', marginTop: 'auto', marginBottom: 'auto' }}
			className="main"
			onClick={() => {
				setIsOpen(!isOpen);
			}}>
			<Select
				placeholder={<div style={{ color: 'black' }}>{firstQuery}</div>}
				options={queryNames}
				components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
				value={firstQuery}
				styles={{ control: (prevStyle, state) => ({ ...prevStyle, width: '125px' }) }}
				onChange={(e) => {
					// check if the queryname exists, it if doesnt create a new query
					
					// console.log(e.value, queries, queries.some(q => q[0].name === e.value))
					const selectedQry = queries.find(q => q[0].name === e.value)
					// console.log('selectedQ: ', selectedQ);

					if (selectedQry?.length) {
						setSelectedQuery(selectedQry)
					} else {
						const newQuery = [{
							league: [],
							date: new Date(new Date().setDate(new Date().getDate() + 30)),
							minMaxOdds: [-1000, 1000],
							minMaxEv: [0, 100],
							minMaxEvFilterOpen: false,
							minMaxOddsFilterOpen: false,
							sport: [],
							book: [],
							sharp: [],
							market: [],
							marketType: [],
							name: e.value,
						}]
						setQueries([...queries, newQuery])
						setSelectedQuery(newQuery)
					}		
				}}
			/>
		</div>
	);
};
