import { formControlClasses, Tooltip } from '@mui/material'
import onexbetLogo from './onexbet.webp'
import eight88SportLogo from './888sport.webp'
import action247Logo from './action247.webp'
import bcGameLogo from './bc_game.webp'
import bet99Logo from './bet99.webp'
import ballyBetLogo from './BallyBet.webp'
import betAnySportsLogo from './betanysports.jpg'
import betRightLogo from './betright.webp'
import betDEXLogo from './betdex.webp'
import betDSILogo from './betdsi.webp'
import betDSILogoSBLogo from './betdsi_superbook.webp'
import betMGMLogo from './BetMGM.png'
import betOnlineLogo from './BetOnline.png'
import betOpenlyLogo from './betopenly.webp'
import betOpenlyDFSLogo from './betopenly.webp'
import betRiversLogo from './BetRivers.png'
import betRiversNYLogo from './BetRivers.png'
import betUSLogo from './BetUS.png'
import betVictor from './betvictor.webp'
import betano from './betano.webp'
import betcris from './betcris.webp'
import betfair from './betfair.webp'
import betfairExchangeLogo from './betfairexchange.webp'
import betfairExchangeLayLogo from './betfairexchange.webp'
import betfredLogo from './betfred.webp'
import betnacionalLogo from './betnacional.webp'
import betplayLogo from './betplay.webp'
import betrLogo from './betr_sb.webp'
import betrPicksLogo from './betr_picks.webp'
import betrPicksAllLogo from './betr_picks.webp'
import betrPicksBoostedLogo from './betr_picks.webp'
import betsafeLogo from './betsafe.webp'
import betssonLogo from './betsson.webp'
import betwayLogo from './betway.webp'
import bodogLogo from './bodog.webp'
import bookMakerLogo from './bookmaker.webp'
import boomFantasyLogo from './boomfantasy.webp'
import borgataLogo from './borgata.webp'
import bovadaLogo from './Bovada.png'
import caesarsLogo from './Caesars.png'
import caesarsLouisianaLogo from './Caesars.png'
import caesarsMaineLogo from './Caesars.png'
import caesarsNewYorkLogo from './Caesars.png'
import caesarsOntarioLogo from './Caesars.png'
import caesarsPennsylvaniaLogo from './Caesars.png'
import caesarsTennesseeLogo from './Caesars.png'
import casumoLogo from './casumo.webp'
import circaSportsLogo from './circasports.webp'
import circaVegasLogo from './circavegas.webp'
import codereLogo from './codere.webp'
import coolbetLogo from './coolbet.webp'
import crabSportsLogo from './crab_sports.webp'
import dabbleLogo from './dabble.webp'
import dabbleAustraliaLogo from './dabble.webp'
import dafabetLogo from './dafabet.webp'
import desertDiamondLogo from './desertdiamond.webp'
import draftkingsLogo from './DraftKings.png';
import draftKingsPick6Logo from './DraftKings.png';
import espnBetLogo from './espnbet.webp'
import eliteBetLogo from './espnbet.webp'
import fanDuelLogo from './Fanduel_Shield_2022.png'
import fanDuelOntarioLogo from './Fanduel_Shield_2022.png'
import fanaticsLogo from './Fanatics-Sportsbook-logo.webp'
import fireKeepersLogo from './firekeepers.webp'
import fliffLogo from './fliff.webp'
import fourWindsLogo from './fourwinds.webp'
import ggBetLogo from './GGBet.webp'
import galerabetLogo from './galera.webp'
import goldenNuggetLogo from './goldennugget.webp'
import hardRockLogo from './hardrock.jpg'
import hotStreakLogo from './hotstreak.webp'
import juiceBetLogo from './juicebet.webp'
import kuttLogo from './kutt.webp'
import ladbrokesLogo from './ladbrokes.webp'
import ladbrokesAustraliaLogo from './ladbrokes.webp'
import leoVegasLogo from './leovegas.webp'
import miseOJeuLogo from './miseojeu.webp'
import myBookieLogo from './mybookie.webp'
import nedsLogo from './neds.webp'
import northStarBetsLogo from './northstarbets.webp'
import novigLogo from './Novig.webp'
import parlayPlayLogo from './parlayplay.webp'
import picklebetLogo from './picklebet.webp'
import pinnacleLogo from './pinnacle.webp'
import pinnyLogo from './pinnacle.webp'
import playAlbertaLogo from './PlayAlberta.webp'
import playEagleLogo from './playeagle.webp'
import playNowLogo from './playnow.webp'
import pointsBetAustraliaLogo from './Pointsbet.png'
import pointsBetOntarioLogo from './Pointsbet.png'
import prizePicksLogo from './prizepicks.webp'
import prizePicks5or6PickLogo from './prizepicks.webp'
import prizePicksDemonsAndGoblinsLogo from './prizepicks.webp'
import prolineLogo from './proline.webp'
import prophetXLogo from './prophetx.webp'
import rebetLogo from './rebet.webp'
import rebetPropsCityLogo from './rebet_props_city.webp'
import resortsWorldBetLogo from './resortsworldbet.webp'
import sboBetLogo from './SBobet_logo.png'
import stnSportsLogo from './stnsports.webp'
import sxBetLogo from './sxbet.webp'
import sleeperLogo from './sleeper.webp'
import sportingbetLogo from './sportingbet.webp'
import sportsInteractionLogo from './sportsinteraction.webp'
import sportsbetLogo from './sportsbet.webp'
import sportsbettingAgLogo from './sportsbettingag.webp'
import sporttradeLogo from './Sporttrade-icon-on-black.png'
import sporttradeColoradoLogo from './Sporttrade-icon-on-black.png'
import sportzinoLogo from './sportzino.webp'
import stakeLogo from './stake.webp'
import sugarHouseLogo from './sugarhouse.webp'
import superbetLogo from './superbet.png'
import tabLogo from './tab.jpg'
import tabNewZealandLogo from './tab.jpg'
import tabTouchLogo from './tabtouch.png'
import thrillzzLogo from './thrillz.webp'
import tonyBetLogo from './tonybet.webp'
import topSportLogo from './topsport.png'
import twinSpiresLogo from './twinspires.webp'
import underdogFantasyLogo from './underdogfantasy.webp'
import underdogFantasy3or5PickLogo from './underdogfantasy.webp'
import underdogFantasy6PickLogo from './underdogfantasy.webp'
import underdogSportsbookLogo from './underdog_sportsbook.jpg'
import unibetLogo from './unibet_fullname.png'
import unibetAustraliaLogo from './unibet_fullname.png'
import unibetUnitedKingdomLogo from './unibet_fullname.png'
import vividPicksLogo from './vividpicks.png'
import vividPicks4PickLogo from './vividpicks.png'
import williamHillLogo from './williamhill.jpg'
import windCreekLogo from './windcreek.png'
import xBetLogo from './xbet.jpg'
import bet105Logo from './bet105.webp'
import bet365Logo from './bet365.webp'
import betJACKLogo from './betjack.png'
import betPARXLogo from './betparx.webp'
import betrAustraliaLogo from './betr_australia.webp'
import betwhaleLogo from './betwhale.webp'
import bwinLogo from './bwin.webp'
import partypokerLogo from './partypoker.webp'
import theScoreLogo from './thescore.webp'

const sportsbookLogoTable = {
    "1XBet": onexbetLogo,
    "888sport": eight88SportLogo,
    "Action 24/7": action247Logo,
    "BC.GAME": bcGameLogo,
    "BET99": bet99Logo,
    "Bally Bet": ballyBetLogo,
    "Bet Right": betRightLogo,
    "BetAnySports": betAnySportsLogo,
    "BetDEX": betDEXLogo,
    "BetDSI": betDSILogo,
    "BetDSI (Superbook)": betDSILogoSBLogo,
    "BetMGM": betMGMLogo,
    "BetOnline": betOnlineLogo,
    "BetOpenly": betOpenlyLogo,
    "BetOpenly (Instant DFS)": betOpenlyDFSLogo,
    "BetRivers": betRiversLogo,
    "BetRivers (New York)": betRiversNYLogo,
    "BetUS": betUSLogo,
    "BetVictor": betVictor,
    "Betano": betano,
    "Betcris": betcris,
    "Betfair": betfair,
    "Betfair Exchange": betfairExchangeLogo,
    "Betfair Exchange (Lay)": betfairExchangeLayLogo,
    "Betfred": betfredLogo,
    "Betnacional": betnacionalLogo,
    "Betplay": betplayLogo,
    "Betr": betrLogo,
    "Betr Picks": betrPicksLogo,
    "Betr Picks (All)": betrPicksAllLogo,
    "Betr Picks (BOOSTED)": betrPicksBoostedLogo,
    "Betsafe": betsafeLogo,
    "Betsson": betssonLogo,
    "Betway": betwayLogo,
    "Bodog": bodogLogo,
    "BookMaker": bookMakerLogo,
    "Boom Fantasy (5 Pick Insured)": boomFantasyLogo,
    "Borgata": borgataLogo,
    "Bovada": bovadaLogo,
    "Caesars": caesarsLogo,
    "Caesars (Louisiana)": caesarsLouisianaLogo,
    "Caesars (Maine)": caesarsMaineLogo,
    "Caesars (New York)": caesarsNewYorkLogo,
    "Caesars (Ontario)": caesarsOntarioLogo,
    "Caesars (Pennsylvania)": caesarsPennsylvaniaLogo,
    "Caesars (Tennessee)": caesarsTennesseeLogo,
    "Casumo": casumoLogo,
    "Circa Sports": circaSportsLogo,
    "Circa Vegas": circaVegasLogo,
    "Codere": codereLogo,
    "Coolbet": coolbetLogo,
    "Crab Sports": crabSportsLogo,
    "Dabble (3 or 5 Pick)": dabbleLogo,
    "Dabble (Australia)": dabbleAustraliaLogo,
    "Dafabet": dafabetLogo,
    "Desert Diamond": desertDiamondLogo,
    "DraftKings": draftkingsLogo,
    "DraftKings (Pick 6)": draftKingsPick6Logo,
    "ESPN BET": espnBetLogo,
    "Elite Bet": eliteBetLogo,
    "FanDuel": fanDuelLogo,
    "FanDuel (Ontario)": fanDuelOntarioLogo,
    "Fanatics": fanaticsLogo,
    "FireKeepers": fireKeepersLogo,
    "Fliff": fliffLogo,
    "Four Winds": fourWindsLogo,
    "GGBet": ggBetLogo,
    "Galera.bet": galerabetLogo,
    "Golden Nugget": goldenNuggetLogo,
    "Hard Rock": hardRockLogo,
    "HotStreak": hotStreakLogo,
    "JuiceBet": juiceBetLogo,
    "Kutt": kuttLogo,
    "Ladbrokes": ladbrokesLogo,
    "Ladbrokes (Australia)": ladbrokesAustraliaLogo,
    "LeoVegas": leoVegasLogo,
    "Mise-o-jeu": miseOJeuLogo,
    "MyBookie": myBookieLogo,
    "Neds": nedsLogo,
    "NorthStar Bets": northStarBetsLogo,
    "Novig": novigLogo,
    "ParlayPlay": parlayPlayLogo,
    "Picklebet": picklebetLogo,
    "Pinnacle": pinnacleLogo,
    "Pinny": pinnyLogo,
    "Play Alberta": playAlbertaLogo,
    "Play Eagle": playEagleLogo,
    "PlayNow": playNowLogo,
    "PointsBet (Australia)": pointsBetAustraliaLogo,
    "PointsBet (Ontario)": pointsBetOntarioLogo,
    "PrizePicks": prizePicksLogo,
    "PrizePicks (5 or 6 Pick Flex)": prizePicks5or6PickLogo,
    "PrizePicks (Demons and Goblins)": prizePicksDemonsAndGoblinsLogo,
    "Proline": prolineLogo,
    "Prophet X": prophetXLogo,
    "Rebet": rebetLogo,
    "Rebet Props City": rebetPropsCityLogo,
    "Resorts World Bet": resortsWorldBetLogo,
    "SBOBET": sboBetLogo,
    "STN Sports": stnSportsLogo,
    "SX Bet": sxBetLogo,
    "Sleeper": sleeperLogo,
    "Sportingbet": sportingbetLogo,
    "Sports Interaction": sportsInteractionLogo,
    "Sportsbet": sportsbetLogo,
    "Sportsbetting.ag": sportsbettingAgLogo,
    "Sporttrade": sporttradeLogo,
    "Sporttrade (Colorado)": sporttradeColoradoLogo,
    "Sportzino": sportzinoLogo,
    "Stake": stakeLogo,
    "SugarHouse": sugarHouseLogo,
    "Superbet": superbetLogo,
    "TAB": tabLogo,
    "TAB (New Zealand)": tabNewZealandLogo,
    "TABtouch": tabTouchLogo,
    "Thrillzz": thrillzzLogo,
    "TonyBet": tonyBetLogo,
    "TopSport": topSportLogo,
    "TwinSpires": twinSpiresLogo,
    "Underdog Fantasy": underdogFantasyLogo,
    "Underdog Fantasy (3 or 5 Pick)": underdogFantasy3or5PickLogo,
    "Underdog Fantasy (6 Pick)": underdogFantasy6PickLogo,
    "Underdog Sportsbook": underdogSportsbookLogo,
    "Unibet": unibetLogo,
    "Unibet (Australia)": unibetAustraliaLogo,
    "Unibet (United Kingdom)": unibetUnitedKingdomLogo,
    "Vivid Picks": vividPicksLogo,
    "Vivid Picks (4 Pick)": vividPicks4PickLogo,
    "William Hill": williamHillLogo,
    "Wind Creek": windCreekLogo,
    "XBet": xBetLogo,
    "bet105": bet105Logo,
    "bet365": bet365Logo,
    "betJACK": betJACKLogo,
    "betPARX": betPARXLogo,
    "betr (Australia)": betrAustraliaLogo,
    "betwhale": betwhaleLogo,
    "bwin": bwinLogo,
    "partypoker": partypokerLogo,
    "theScore": theScoreLogo
}

export const LogoGenerator = (props) => {
    let sportsbookName = props.sportsbook;
    if (sportsbookName === 'Betway (Alaska)') {
        sportsbookName = "FanDuel" //TODO: remove this once fanduel is fixed
    }

    if (!sportsbookLogoTable[sportsbookName]) {
        return <div>
            {sportsbookName}
        </div>
    } else {
        return (
            <div>
                <Tooltip title={sportsbookName}>
                    <img
                        width={50}
                        style={{
                            borderRadius: "10px"
                        }
                        }
                        src={sportsbookLogoTable[sportsbookName]}
                    />
                </Tooltip>
            </div>)
    }

}