export const linkConverter = (link) => {
	let templink;
	if (!link) {
		return link;
	}

	if (link.includes('fanduel')) {
		// https://sportsbook.fanduel.com/addToBetslip?marketId=42.465590572&selectionId=22549472
		// https://on.sportsbook.fanduel.ca
		templink = link.replace(/sportsbook./g, 'on.sportsbook.').replace(/.com/g, '.ca');
		return templink;
	} else if (link.includes('pinnacle')) {
		// https://www.pinnacle.ca/en/
		// https://www.pinnacle.com/en/basketball/ncaa/east-carolina-vs-stetson/1601045681/#all
		templink = link.replace(/.com/g, '.ca');
		return templink;
	} else if (link.includes('bet365')) {
		// https://www.pinnacle.ca/en/
		// https://www.pinnacle.com/en/basketball/ncaa/east-carolina-vs-stetson/1601045681/#all
		templink = link.replace(/.com/g, '.ca');
		return templink;
	} else if (link.includes('draftkings')) {
		// https://sportsbook.draftkings.com/event/31410302?outcomes=0ML77653060_1
		// https://sportsbook.draftkings.com
		return link;
	} else if (link.includes('betmgm')) {
		// https://sports.<STATE>.betmgm.com/en/sports?options=2:7508370-172404328-594703178&type=Single
		// https://sports.on.betmgm.ca/en/sports
		templink = link.replace(/.com/g, '.ca');
		return templink;
	} else {
		return link;
	}
};
