import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const verifyDiscord = async (username, password, discordId) => {

    const res = (await axios.post(`${backendUrl}/preferences_feeds?username=${username}&password=${password}&discord_username=${discordId}`, {})).data;
    
    return res;
}