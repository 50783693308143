import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const saveNotificationPreferences = async (username, password, queryRows, queryName) => {
    const queryRowsCopy = JSON.parse(JSON.stringify(queryRows));
    const id = queryRowsCopy[0].id
    for (const queryRow of queryRowsCopy) {
        queryRow.minMaxEvFilterOpen = false
        queryRow.minMaxOddsFilterOpen = false
        queryRow.name = queryName
        queryRow.id = id
    }
    console.log('queryRowsCopy: ', queryRowsCopy);

    const res = (await axios.post(`${backendUrl}/preferences_feeds?username=${username}&password=${password}`, queryRowsCopy)).data

    // const res = (await axios.post(`${backendUrl}/preferences?username=${username}&password=${password}&queryName=${queryName}`, queryRowsCopy)).data;
    return true
    // return res;
}

export const deleteQuery = async (username, password, queryName) => {

    const res = await axios.delete(`${backendUrl}/query?username=${username}&password=${password}&queryName=${queryName}`)

    return true
}

export const setDefaultQuery = async (username, password, queryName) => {

    const res = await axios.patch(`${backendUrl}/default_query?username=${username}&password=${password}&queryName=${queryName}`)

    return true
}