export const MinMaxOddsComponent = (props) => {
  return (<div style={{ cursor: 'pointer', border: props.queryRow.minMaxOddsFilterOpen ? "1px solid" : "1px solid #cccccc", borderRadius: "6px", width: "200px", backgroundColor: "#ffffff", height: props.queryRow.minMaxOddsFilterOpen ? "" : "38px", marginTop: "0.5rem" }}>
    <div onClick={() => {
      const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
      queryRowsCopy.forEach((row, index) => {
        if (index === props.index) {
          row.minMaxOddsFilterOpen = !row.minMaxOddsFilterOpen
        }
      })
      props.setQueryRows(queryRowsCopy)
    }} style={{ backgroundColor: props.queryRow.minMaxOddsFilterOpen ? "#0f1b33" : "", color: props.queryRow.minMaxOddsFilterOpen ? "white" : "", borderTopLeftRadius: "6px", borderTopRightRadius: "6px", paddingLeft: "8px", paddingTop: "8px" }}>
      Min/Max Odds <span style={{fontSize: "12px"}}>{ `[${props.queryRow.minMaxOdds[0] === -10000 ? '-∞' : props.queryRow.minMaxOdds[0]}, ${props.queryRow.minMaxOdds[1] === 10000 ? '∞' : props.queryRow.minMaxOdds[1]}]`}</span>
    </div>
    {
      props.queryRow.minMaxOddsFilterOpen && <div style={{ padding: "0.25rem" }}>
        <div style={{ display: 'grid', gridTemplateColumns: "auto auto" }}>
          <div>
            <p>Min Odds: </p>

            <p>Max Odds: </p>
          </div>
          <div>
            <div style={{ marginTop: '1rem' }}>
              <input style={{ width: "35px", marginLeft: '0.4rem' }} onChange={(e) => {
                const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
                queryRowsCopy.forEach((row, index) => {
                  if (index === props.index) {
                    row.minMaxOdds = [e.target.value, row.minMaxOdds[1]]
                  }
                })
                props.setQueryRows(queryRowsCopy)
              }} value={props.queryRow.minMaxOdds[0] === -10000 ? '-∞' : props.queryRow.minMaxOdds[0]} />
            </div>
            <div style={{ marginTop: '0.8rem' }}>
              <input style={{ width: "35px", marginLeft: '0.4rem' }} onChange={(e) => {

                const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
                queryRowsCopy.forEach((row, index) => {
                  if (index === props.index) {
                    row.minMaxOdds = [row.minMaxOdds[0], e.target.value]
                  }
                })
                props.setQueryRows(queryRowsCopy)
              }} value={props.queryRow.minMaxOdds[1] === 10000 ? '∞' : props.queryRow.minMaxOdds[1]} />
            </div>
          </div>
        </div>
      </div>
    }
  </div>)
}