import { useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { MinMaxEvComponent } from './MinMaxEv';
import { MinMaxOddsComponent } from './MinMaxOdds';
import { DatePickerComponent } from './DatePicker';
import { QueryRow } from './QueryRow';
import saveIcon from '../../../save_icon.svg'
import './FiltersBar.css'
import { Button, Switch, TextField, Tooltip, Typography, styled } from '@mui/material';
import { Verified } from '@mui/icons-material';

// const animatedComponents = makeAnimated();

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

export const NotificationsBar = (props) => {
  // console.log('props: ', props.filterOptions);
  const {setIsNotificationFiltersOpen, setIsFiltersOpen, saveNotificationSettings} = props
  return <div>
    <div>
      <div style={{ display: 'flex', flexDirection: "row", backgroundColor: "#0E1B33", marginLeft: "2rem", marginRight: '2rem', borderRadius: '12px', marginTop: '2rem', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <h3 style={{ marginLeft: '1rem', userSelect: "none", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: 'none' }}>
          Notifications
        </h3>
        <div onClick={() => {
          setIsNotificationFiltersOpen(false)
          setIsFiltersOpen(true)
        }} style={{ marginRight: "1rem", borderRadius: '8px', height: "min-content", marginTop: "auto", marginBottom: 'auto', cursor: "pointer", marginLeft: 'auto' }}>
          <p style={{ marginTop: '5px', marginBottom: "5px", padding: '4px' }} >Website</p>
        </div>
        <div style={{ marginRight: "1rem", backgroundColor: "white", borderRadius: '8px', height: "min-content", color: "black", marginTop: "auto", marginBottom: 'auto', cursor: "pointer", padding: "4px" }}>
          <p style={{ marginTop: '5px', marginBottom: "5px" }}>Notifications</p>
        </div>
        <div className='button' style={{ marginRight: "1rem", borderRadius: '8px', height: "min-content", marginTop: "auto", marginBottom: 'auto', cursor: "pointer", display: 'flex', flexDirection: 'row', marginLeft: "3rem" }}
          onClick={() => {
            saveNotificationSettings()
          }}
        >
          <p style={{ marginTop: '5px', marginBottom: "5px", marginRight: '0.25rem' }}>Save Notifications</p>
          <img alt='save' className='filter' src={saveIcon} />
        </div>
      </div>
      <div style={{ backgroundColor: "#DC8D16", height: "6px", marginLeft: "2rem", marginRight: '2rem', borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      </div>
    </div>
    <div style={{
      marginLeft: "2rem",
      backgroundColor: "#f1f1f3",
      marginRight: "2rem",
      paddingTop: "1.5rem",
      display: 'flex'
    }}>

    </div>

    {
      props?.queryRows.map((queryRow, index) => <QueryRow index={index} setQueryRows={props.setQueryRows} queryRows={props.queryRows} queryRow={queryRow} last={index === props.queryRows.length - 1} filterOptions={props.filterOptions} addRowComponent={<Tooltip title="Add new search row">
        <div className='run-button' style={{ marginLeft: "auto", height: 'min-content', alignSelf: "center", color: "#0f1b33", marginRight: '1rem', position: 'relative', right: '10px' }}
          onClick={() => {
            const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
            queryRowsCopy.push({ league: [], date: new Date(new Date().setDate(new Date().getDate() + 10)), minMaxOdds: [-1000, 1000], minMaxEv: [0, 100], minMaxEvFilterOpen: false, minMaxOddsFilterOpen: false, sport: [], book: [], sharpBook: [], market: [] })
            props.setQueryRows(queryRowsCopy)
          }}
        >
          +
        </div>
      </Tooltip>} />)
    }
    {/* <QueryRow />
    <QueryRow last/> */}
    <div style={{ marginLeft: '2rem', marginRight: "2rem", display: "flex", flexDirection: "row" }}>
      {/* <Tooltip title="Remove last search row">
        <div className='run-button'
          onClick={() => {
            const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
            queryRowsCopy.pop()
            props.setQueryRows(queryRowsCopy)
          }}
        >
          -
        </div>
      </Tooltip> */}
      {/* <Tooltip title="Add new search row">
        <div className='run-button' style={{ marginLeft: "auto" }}
          onClick={() => {
            const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
            queryRowsCopy.push({ league: [], date: new Date(new Date().setDate(new Date().getDate() + 10)), minMaxOdds: [0, 100], minMaxEv: [0, 100], minMaxEvFilterOpen: false, minMaxOddsFilterOpen: false, sport: [], book: [], sharpBook: [], market: [] })
            props.setQueryRows(queryRowsCopy)
          }}
        >
          +
        </div>
      </Tooltip> */}
    </div>
  </div>
}