import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react"
import { retrieveUsernameAndPassword } from "../../api/retrieveUsernameAndPassword";
import { backendUrl } from "../../api/runEvScan";
import { login } from "../../api/login";


export function Verify() {

    const urlParams = new URLSearchParams(window.location.search);
    const i = urlParams.get('i');

    const [isVerified, setIsVerified] = useState('undefined');

    useEffect(() => {

        const loginFn = async () => {
            const { username, password } = retrieveUsernameAndPassword();
            const loginInfo = await login(String(username), String(password));

            // verify the discord user
            const verify = await axios.post(`${backendUrl}/preferences_feeds_verify?i=${i}&username=${username}&password=${password}`);

            // console.log("🚀 ~ loginFn ~ verify:", verify.data)
            if (verify.data || loginInfo.data.discord_verified) {
                setIsVerified('true')
            } else {
                setIsVerified('false')
            }

        }

        loginFn()

    })

    return (<div>
        {
            isVerified === "true" && <div className="pl-6">
                <h1>
                    Discord user verified!
                </h1>
                <p className="pt-6">
                    You may close this page. +EV plays will now stream to your Discord DMs
                </p>
            </div>
        }
        {
            isVerified === 'false' && <div className="pl-6">
                Discord user not verified
            </div>
        }
        {
            isVerified === 'undefined' && <div className="pl-6">
                <CircularProgress />
            </div>
        }
    </div>)
}