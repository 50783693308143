import { login } from '../../api/login';
import { signUp } from '../../api/signUp';
import valuebeaverLogo from '../../valueabeaverlogo.png';
// import { FaUserAlt } from "react-icons/fa";
// import { FaLock } from "react-icons/fa";

import './SignIn.css'


export const SignIn = (props) => {
    return (
        <div className='main-wrapper'>
            <div className='wrapper'>
                <img
                    className="logo-signin"
                    src={valuebeaverLogo}
                    height={100}
                    width={100}
                    alt="ValueBeaver Logo"
                />
                <div className='box'>
                    <h1>
                        Welcome to ValueBeaver.
                    </h1>
                    <div style={{ height: "100%" }}>
                        {
                            !props.isEnteringUserInfoSignUp && <div>
                                <div>
                                    <div className="input-box">
                                        <input placeholder='Username' variant='outlined' value={props.username} onChange={(e) => {
                                            props.setUsername(e.target.value)
                                        }} />
                                        {/* <FaUserAlt className='icon' /> */}
                                    </div>
                                    <div className="input-box">
                                        <input placeholder='Password' type='password' variant='outlined' value={props.password} onChange={(e) => {
                                            props.setPassword(e.target.value)
                                        }} />
                                        {/* <FaLock className='icon' /> */}
                                    </div>
                                </div>

                                <button onClick={async () => {
                                    if (props.username !== '' && props.password !== '') {
                                        const lgin = await login(props.username, props.password);
                                        if (lgin.data.active) {
                                            props.setIsSignedIn(true);
                                            // window.location.replace('/')
                                        } else {
                                            props.setSignInError(true);
                                        }
                                    }
                                }}>
                                    Log in
                                </button>
                                {
                                    props.signInError && <div>Sign in error</div>
                                }
                                <div className='register-link'>
                                    <p>Dont have an account? <span
                                        onClick={() => {
                                            props.setIsEnteringUserInfoSignUp(true)
                                        }}
                                    >
                                        Sign Up
                                    </span>
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            props.isEnteringUserInfoSignUp && !props.isEnteringUserInfo && (
                                <div style={{ height: '100%' }}>
                                    <div>
                                        <div>
                                            <input className='signin-field' placeholder='username' variant='outlined' value={props.username} onChange={(e) => {
                                                props.setUsername(e.target.value)
                                            }} />
                                        </div>
                                        <div>
                                            <input className='signin-field' placeholder='password' type='password' variant='outlined' value={props.password} onChange={(e) => {
                                                props.setPassword(e.target.value)
                                            }} />
                                        </div>
                                        <div>
                                            <input className='signin-field' placeholder='email' type='email' variant='outlined' value={props.email} onChange={(e) => {
                                                props.setEmail(e.target.value)
                                            }} />
                                        </div>
                                    </div>

                                    <button onClick={async () => {
                                        if (props.username !== '' && props.password !== '') {
                                            const lgin = await signUp(props.username, props.password, props.email);
                                            if (lgin.data.message && lgin.data.message !== "Signed up") {
                                                props.setSignUpError(true);
                                            }
                                            else {
                                                props.setIsSignUpComplete(true);
                                                localStorage.setItem("username", props.username);
                                                localStorage.setItem("password", props.password);
                                                window.location.reload(false)
                                            }
                                        }
                                    }}>
                                        Sign Up
                                    </button>
                                    {
                                        props.signUpError && <div style={{ textAlign: 'center', marginTop: "12px" }}>Sign in error</div>
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div>
                        {/* {
                            props.isEnteringUserInfoSignUp && !props.isEnteringUserInfo && (
                                <div>
                                    <div>
                                        <div>
                                            <input placeholder='username' variant='outlined' value={props.username} onChange={(e) => {
                                                props.setUsername(e.target.value)
                                            }} />
                                        </div>
                                        <div>
                                            <input placeholder='password' type='password' variant='outlined' value={props.password} onChange={(e) => {
                                                props.setPassword(e.target.value)
                                            }} />
                                        </div>
                                        <div>
                                            <input placeholder='email' type='email' variant='outlined' value={props.email} onChange={(e) => {
                                                props.setEmail(e.target.value)
                                            }} />
                                        </div>
                                    </div>

                                    <button onClick={async () => {
                                        if (props.username !== '' && props.password !== '') {
                                            const lgin = await signUp(props.username, props.password, props.email);
                                            if (lgin.data.message && lgin.data.message !== "Signed up") {
                                                props.setSignUpError(true);
                                            }
                                            else {
                                                props.setIsSignUpComplete(true);
                                                localStorage.setItem("username", props.username);
                                                localStorage.setItem("password", props.password);
                                            }
                                        }
                                    }}>
                                        Sign Up
                                    </button>
                                    {
                                        props.signInError && <div className='text-red-600 font-bold'>Sign in error</div>
                                    }
                                </div>
                            )
                        } */}

                    </div>
                </div>
                {
                    (props.isEnteringUserInfo || props.isEnteringUserInfoSignUp) && (
                        <button style={{ fontSize: "x-large", float: "inline-end", color: 'white', backgroundColor: '#0f1b33', bottom: '14px', borderRadius: '16px', padding: '6px' }} onClick={async () => {
                            props.setIsSignedIn(false);
                            props.setIsEnteringUserInfo(false);
                            props.setIsEnteringUserInfoSignUp(false);
                        }}>
                            Back
                        </button>
                    )
                }
            </div>
        </div>
    )
}















// export const SignIn = (props) => {
//     return (
//         <div className='wrapper'>
//             <div className='logobox'>
//                 <img
//                     className="mb-4 rounded-full max-w-none cursor-pointer logo"
//                     src={valuebeaverLogo}
//                     height={100}
//                     width={100}
//                     alt="Valuebeaver Logo"
//                 />
//             </div>
//             <div className='input-wrapper'>
//                 <h1>
//                     Welcome to ValueBeaver.
//                 </h1>
//                 <div>
//                     {
//                         !props.isEnteringUserInfoSignUp && <div style={{ color: "#F5F5F5", width: "fit-content", marginLeft: "auto", marginRight: "auto", padding: "20px", borderRadius: "10px" }}>
//                             <div className='flex flex-column'>
//                                 <div className='flex flex-column text-black input-box'>
//                                     <input placeholder='username' variant='outlined' value={props.username} onChange={(e) => {
//                                         props.setUsername(e.target.value)
//                                     }} />
//                                 </div>
//                                 <div className='flex flex-column text-black input-box'>
//                                     <input placeholder='password' type='password' variant='outlined' value={props.password} onChange={(e) => {
//                                         props.setPassword(e.target.value)
//                                     }} />
//                                 </div>
//                             </div>

//                             <button style={{
//                                 marginLeft: "auto", marginRight: "auto", fontSize: "medium",
//                                 color: 'white', backgroundColor: 'black', padding: "8px", borderRadius: '8px', fontWeight: "bold"
//                             }} onClick={async () => {
//                                 if (props.username !== '' && props.password !== '') {
//                                     const lgin = await login(props.username, props.password);
//                                     if (lgin.data.active) {
//                                         props.setIsSignedIn(true);
//                                         // window.location.replace('/')
//                                     } else {
//                                         props.setSignInError(true);
//                                     }
//                                 }
//                             }}>
//                                 Log in
//                             </button>
//                             {
//                                 props.signInError && <div className='text-red-600 font-bold'>Sign in error</div>
//                             }
//                             <p style={{ color: 'black', marginTop: '20px' }}>Dont have an account? <span style={{ color: '#dc8d18', fontWeight: "bold", cursor: 'pointer' }}
//                                 onClick={() => {
//                                     props.setIsEnteringUserInfoSignUp(true)
//                                 }}
//                             >
//                                 Sign Up
//                             </span>
//                             </p>
//                         </div>
//                     }

//                 </div>
//                 <div style={{ marginLeft: "auto", textAlign: "center" }}>
//                     <div>
//                         {
//                             props.isEnteringUserInfoSignUp && !props.isEnteringUserInfo && (
//                                 <div style={{ color: "#F5F5F5", width: "fit-content", marginLeft: "auto", marginRight: "auto", padding: "60px", borderRadius: "10px" }}>
//                                     <div className='flex flex-column'>
//                                         <div style={{ maxWidth: '200px', marginLeft: "auto", marginRight: "auto", marginBottom: "20px" }} className='flex flex-column '>
//                                             <input placeholder='username' className='mt-2' variant='outlined' value={props.username} onChange={(e) => {
//                                                 props.setUsername(e.target.value)
//                                             }} />
//                                         </div>
//                                         <div style={{ maxWidth: '200px', marginLeft: "auto", marginRight: "auto", marginBottom: "45px", border: "none", borderRadius: "40px" }} className='flex flex-column '>
//                                             <input placeholder='password' className='mt-2' type='password' variant='outlined' value={props.password} onChange={(e) => {
//                                                 props.setPassword(e.target.value)
//                                             }} />
//                                         </div>
//                                         <div style={{ maxWidth: '200px', marginLeft: "auto" }} className='flex flex-column '>
//                                             <input placeholder='email' type='email' variant='outlined' value={props.email} onChange={(e) => {
//                                                 props.setEmail(e.target.value)
//                                             }} />
//                                         </div>
//                                     </div>

//                                     <button style={{ marginLeft: "auto", marginRight: "auto", fontSize: "x-large", color: 'white', backgroundColor: "black", marginTop: '14px' }} onClick={async () => {
//                                         if (props.username !== '' && props.password !== '') {
//                                             const lgin = await signUp(props.username, props.password, props.email);
//                                             if (lgin.data.message && lgin.data.message !== "Signed up") {
//                                                 props.setSignUpError(true);
//                                             }
//                                             else {
//                                                 props.setIsSignUpComplete(true);
//                                                 localStorage.setItem("username", props.username);
//                                                 localStorage.setItem("password", props.password);
//                                             }
//                                         }
//                                     }}>
//                                         Sign Up
//                                     </button>
//                                     {
//                                         props.signInError && <div className='text-red-600 font-bold'>Sign in error</div>
//                                     }
//                                 </div>
//                             )
//                         }

//                     </div>
//                 </div>
//                 {
//                     (props.isEnteringUserInfo || props.isEnteringUserInfoSignUp) && (
//                         <button style={{ marginRight: "-30px", fontSize: "x-large", float: "inline-end", color: 'white', backgroundColor: 'black', bottom: '14px' }} onClick={async () => {
//                             props.setIsSignedIn(false);
//                             props.setIsEnteringUserInfo(false);
//                             props.setIsEnteringUserInfoSignUp(false);
//                         }}>
//                             Back
//                         </button>
//                     )
//                 }
//             </div>
//         </div>
//     )
// }