import axios from 'axios';

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const toggleDiscordNotifications = async (username, password, isDiscordActive) => {
    // console.log('isDiscordActive: ', Boolean(isDiscordActive));
	const res = (await axios.post(`${backendUrl}/preferences_feeds_active?discord_feeds_active=${Boolean(isDiscordActive)}&username=${username}&password=${password}`)).data;
    // console.log('res: ', res);

	return res;
};
