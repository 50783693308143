import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { convertDecimalToAmerican, getComparator, topComparableSportsbooks } from '../EvScanner';
import { useEffect, useMemo, useState } from 'react';
import { LogoGenerator } from '../../LogoGenerator/LogoGenerator';
import { BetPrice } from '../../BetPrice/BetPrice';
import { LineChart } from '@mui/x-charts';
import './Sidebar.css';
import Select from 'react-select';
import { animatedComponents } from '../FiltersBar/QueryRow';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

		function uniq(a) {
							var prims = { boolean: {}, number: {}, string: {} },
								objs = [];

							return a.filter(function (item) {
								var type = typeof item;
								if (type in prims) return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
								else return objs.indexOf(item) >= 0 ? false : objs.push(item);
							});
						}

function nextBiggest(arr) {
	let max = -Infinity,
		result = -Infinity;

	for (const value of arr) {
		const nr = Number(value);

		if (nr > max) {
			[result, max] = [max, nr]; // save previous max
		} else if (nr < max && nr > result) {
			result = nr; // new second biggest
		}
	}

	return result;
}
export const Sidebar = (props) => {
	const { data, oddsHistoryData, setLineCharts, scrollTo, sharpPrice, dumbPrice, sharpBook, dumbBook, betName, selectedBooksIncludedInGraph } = props;

	const { selectedOddsHistoryXAxis } = oddsHistoryData;
	const isUnder = betName.includes('under');

	const [hasSharpPriceChanged, setHasSharpPriceChanged] = useState(false);
	const [hasDumbPriceChanged, setHasDumbPriceChanged] = useState(false);

	const [sharpRow, setSharpRow] = useState();
	const [dumbRow, setDumbRow] = useState();

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('sportsbook');
	let visibleRows = useMemo(() => data?.sort(getComparator(order, orderBy)), [data, order, orderBy]);

	useEffect(() => {
		for (const row of visibleRows) {
			// if it's the row of the sharp or dumbbook, check if the price has changed
			// if the price has changed, then add the PriceChanged indicator

			let sharp = sharpBook;
			if (sharp.includes(',')) {
				sharp = sharp.split(',')[0]
			}
			if (row.sportsbook === sharp || (row.sportsbook === 'Betway (Alaska)' && sharp === 'FanDuel')) {
				setSharpRow(row);
				if (isUnder) {
					if (convertDecimalToAmerican(row.bet_price_under).toFixed(0) != parseInt(sharpPrice)) {
						// console.log(convertDecimalToAmerican(row.bet_price_under).toFixed(0), parseInt(sharpPrice))
						setHasSharpPriceChanged(true);
					}
				} else {
					if (convertDecimalToAmerican(row.bet_price_over).toFixed(0) != parseInt(sharpPrice)) {
						// console.log(convertDecimalToAmerican(row.bet_price_over).toFixed(0), parseInt(sharpPrice))
						setHasSharpPriceChanged(true);
					}
				}
			} else if (row.sportsbook === dumbBook || (row.sportsbook === 'Betway (Alaska)' && dumbBook === 'FanDuel')) {
				setDumbRow(row);
				if (isUnder) {
					if (convertDecimalToAmerican(row.bet_price_under).toFixed(0) != parseInt(dumbPrice)) {
						// console.log(convertDecimalToAmerican(row.bet_price_under).toFixed(0), parseInt(dumbPrice))
						setHasDumbPriceChanged(true);
					}
				} else {
					if (convertDecimalToAmerican(row.bet_price_over).toFixed(0) != parseInt(dumbPrice)) {
						// console.log(convertDecimalToAmerican(row.bet_price_over).toFixed(0), parseInt(dumbPrice))
						setHasDumbPriceChanged(true);
					}
				}
			}

			
		}
	}, [setSharpRow, setDumbRow, setHasDumbPriceChanged, setHasSharpPriceChanged, dumbBook, dumbPrice, sharpBook, sharpPrice, isUnder, visibleRows]);

	try {
		if (typeof selectedOddsHistoryXAxis?.[0] === 'object') {
			const averageOver = visibleRows.filter((e) => e.bet_price_over).reduce((total, next) => total + next.bet_price_over, 0) / visibleRows.length;
			const highOver = Math.max(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));

			const averageUnder = visibleRows.filter((e) => e.bet_price_under).reduce((total, next) => total + next.bet_price_under, 0) / visibleRows.length;
			const highUnder = Math.max(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));
			const sportsbookNames = props.selectedBooksIncludedInGraph;

			const secondHighOver = nextBiggest(visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			const secondHighUnder = nextBiggest(visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			return (
				<div style={{ position: 'sticky', top: 0, borderRadius: '16px', overflowY: 'scroll', height: '82vh' }}>
					{hasSharpPriceChanged && !hasDumbPriceChanged && (
						<div style={{ color: 'red' }}>
							<PriorityHighIcon />
							Sharp Price has changed! ({sharpBook})
						</div>
					)}
					{hasDumbPriceChanged && !hasSharpPriceChanged && (
						<div style={{ color: 'red' }}>
							<PriorityHighIcon />
							Dumb Price has changed! ({dumbBook})
						</div>
					)}
					{hasDumbPriceChanged && hasSharpPriceChanged && (
						<div style={{ textAlign: 'center', color: 'red' }}>
							<PriorityHighIcon />
							<br />
							Both Sharp Price and Dumb Price have changed! ({sharpBook} and {dumbBook})
						</div>
					)}
					<div
						style={{
							backgroundColor: '#0f1b33',
							color: 'white',
							borderTopLeftRadius: '15px',
							borderTopRightRadius: '15px',
							textAlign: 'center',
							paddingTop: '4px',
							fontWeight: 'bold',
							position: 'sticky',
							top: 0,
						}}>
						{data[0].selection} {data[0].selection_points} {data[0].market}
					</div>
					<Table
						style={{ backgroundColor: '#f5f5f5', color: '#0f1b33', borderRadius: '15px' }}
						size="small"
						sx={{
							'& .MuiTableCell-root': {
								padding: '1px',
							},
						}}>
						<TableHead style={{ backgroundColor: '#0f1b33' }}>
							<TableRow style={{}}>
								<TableCell>
									<TableSortLabel
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}></TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_over' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_over');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										<div style={{ textAlign: 'center' }}>Over</div>
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_under' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_under');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Under
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell style={{ maxWidth: '12px' }}>
									<Tooltip title="View all books' histories">
										<div
											style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center', cursor: 'pointer' }}
											onClick={() => {
												props.setSelectedOddsHistorySportsbook(sportsbookNames);
												// props.setSelectedBooksIncludedInGraph([]);
												scrollTo();
												console.log('avg clicked')

											}}>
											Average
										</div>
									</Tooltip>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<div style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center' }}>High</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow style={{backgroundColor: "#dd8d13"}}>
								<TableCell style={{ maxWidth: '10px' }}>
									<div
										style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
										onClick={() => {
											props.setSelectedOddsHistorySportsbook(dumbBook);

											setLineCharts(true);
											scrollTo();
										}}
										className="logo">
										<LogoGenerator sportsbook={dumbBook} />
									</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(dumbRow?.bet_price_over)?.toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(dumbRow?.bet_price_under)?.toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow  style={{backgroundColor: "#dd8d13"}}>
								<TableCell style={{ maxWidth: '10px' }}>
									<div
										style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
										onClick={() => {
											props.setSelectedOddsHistorySportsbook(sharpBook);

											setLineCharts(true);
											scrollTo();
										}}
										className="logo">
										<LogoGenerator sportsbook={sharpBook?.split(',')[0]} />
									</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(sharpRow?.bet_price_over)?.toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(sharpRow?.bet_price_under)?.toFixed(0)} />
								</TableCell>
							</TableRow>
							{visibleRows?.map((row) => {
								let backgroundColorOver = '';
								let backgroundColorUnder = '';

								if (row.bet_price_over === highOver) {
									backgroundColorOver = '#3df57a';
								} else if (row.bet_price_over === secondHighOver) {
									backgroundColorOver = '#afd7c5';
								}
								if (row.bet_price_under === highUnder) {
									backgroundColorUnder = '#3df57a';
								} else if (row.bet_price_under === secondHighUnder) {
									backgroundColorUnder = '#afd7c5';
								}
								let highlightRow = false;
								if (row.sportsbook === 'FanDuel' || row.sportsbook === 'Betway (Alaska)') {
									if (sharpBook === 'FanDuel') {
										highlightRow = true;
									}
								}

								return (
									<TableRow style={{ backgroundColor: row.sportsbook === dumbBook || row.sportsbook === sharpBook || highlightRow ? '#dd8d13' : '' }}>
										<TableCell>
											<div
												style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
												onClick={() => {
													props.setSelectedOddsHistorySportsbook(row.sportsbook);
												}}
												className="logo">
												<LogoGenerator sportsbook={row.sportsbook} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorOver, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_over).toFixed(0)} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorUnder, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_under).toFixed(0)} />
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			);
		} else {
			const averageOver = visibleRows.filter((e) => e.bet_price_over).reduce((total, next) => total + next.bet_price_over, 0) / visibleRows.length;
			const highOver = Math.max(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			// const lowOver = Math.min(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));

			const averageUnder = visibleRows.filter((e) => e.bet_price_under).reduce((total, next) => total + next.bet_price_under, 0) / visibleRows.length;
			const highUnder = Math.max(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));
			// const lowUnder = Math.min(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			const secondHighOver = nextBiggest(visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			const secondHighUnder = nextBiggest(visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			const sportsbookNames = data?.map((oddsHistory) => oddsHistory.sportsbook);

			return (
				<div style={{ position: 'sticky', top: 0, borderRadius: '16px', overflowY: 'scroll', height: '82vh' }}>
					{hasSharpPriceChanged && !hasDumbPriceChanged && (
						<div style={{ color: 'red' }}>
							<PriorityHighIcon />
							Sharp Price has changed! ({sharpBook})
						</div>
					)}
					{hasDumbPriceChanged && !hasSharpPriceChanged && (
						<div style={{ color: 'red' }}>
							<PriorityHighIcon />
							Dumb Price has changed! ({dumbBook})
						</div>
					)}
					{hasDumbPriceChanged && hasSharpPriceChanged && (
						<div style={{ textAlign: 'center', color: 'red' }}>
							<PriorityHighIcon />
							<br />
							Both Sharp Price and Dumb Price have changed! ({sharpBook} and {dumbBook})
						</div>
					)}
					<div
						style={{
							backgroundColor: '#0f1b33',
							color: 'white',
							borderTopLeftRadius: '15px',
							borderTopRightRadius: '15px',
							textAlign: 'center',
							paddingTop: '4px',
							fontWeight: 'bold',
							position: 'sticky',
							top: 0,
						}}>
						{data[0].selection} {data[0].selection_points} {data[0].market}
					</div>
					<Table
						style={{ backgroundColor: '#f5f5f5', color: '#0f1b33', borderRadius: '15px' }}
						size="small"
						sx={{
							'& .MuiTableCell-root': {
								padding: '1px',
							},
						}}>
						<TableHead style={{ backgroundColor: '#0f1b33', position: 'sticky', top: 'auto' }}>
							<TableRow style={{ position: 'sticky', top: 25, backgroundColor: '#0f1b33' }}>
								<TableCell>
									<TableSortLabel
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}></TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_over' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_over');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										<div style={{ textAlign: 'center' }}>Over</div>
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_under' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_under');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Under
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell style={{ maxWidth: '10px' }}>
									<Tooltip title="View all books' histories">
										<div
											style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center', cursor: 'pointer' }}
											onClick={() => {
												props.setSelectedOddsHistorySportsbook(sportsbookNames);
												props.setSelectedBooksIncludedInGraph(uniq([...topComparableSportsbooks, sharpBook, dumbBook]));
												setLineCharts(true);
												scrollTo();
											}}>
											Average
										</div>
									</Tooltip>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<div style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center' }}>High</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow style={{backgroundColor: "#dd8d13"}}>
								<TableCell style={{ maxWidth: '10px' }}>
									<div
										style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
										onClick={() => {
											props.setSelectedOddsHistorySportsbook(dumbBook);

											setLineCharts(true);
											scrollTo();
										}}
										className="logo">
										<LogoGenerator sportsbook={dumbBook} />
									</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(dumbRow?.bet_price_over)?.toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(dumbRow?.bet_price_under)?.toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow  style={{backgroundColor: "#dd8d13"}}>
								<TableCell style={{ maxWidth: '10px' }}>
									<div
										style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
										onClick={() => {
											props.setSelectedOddsHistorySportsbook(sharpBook);

											setLineCharts(true);
											scrollTo();
										}}
										className="logo">
										<LogoGenerator sportsbook={sharpBook} />
									</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(sharpRow?.bet_price_over)?.toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(sharpRow?.bet_price_under)?.toFixed(0)} />
								</TableCell>
							</TableRow>
							{visibleRows?.map((row) => {
								let backgroundColorOver = '';
								let backgroundColorUnder = '';

								if (row.bet_price_over === highOver) {
									backgroundColorOver = '#3df57a';
								} else if (row.bet_price_over === secondHighOver) {
									backgroundColorOver = '#afd7c5';
								}
								if (row.bet_price_under === highUnder) {
									backgroundColorUnder = '#3df57a';
								} else if (row.bet_price_under === secondHighUnder) {
									backgroundColorUnder = '#afd7c5';
								}

								let highlightRow = false;
								if (row.sportsbook === 'FanDuel' || row.sportsbook === 'Betway (Alaska)') {
									if (sharpBook === 'FanDuel') {
										highlightRow = true;
									}
								}

								return (
									<TableRow style={{ backgroundColor: row.sportsbook === dumbBook || row.sportsbook === sharpBook || highlightRow ? '#dd8d13' : '' }}>
										<TableCell>
											<div
												style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
												onClick={() => {
													props.setSelectedOddsHistorySportsbook(row.sportsbook);

													setLineCharts(true);
													scrollTo();
												}}
												className="logo">
												<LogoGenerator sportsbook={row.sportsbook} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorOver, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_over).toFixed(0)} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorUnder, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_under).toFixed(0)} />
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			);
		}
	} catch (e) {
		console.log(e);
		return <></>;
	}
};
