import { useEffect, useState } from 'react';
import { login } from './api/login';
import { SignIn } from './components/SignIn/SignIn';
import { GridLoader } from 'react-spinners';
import { EvScanner } from './components/EvScanner/EvScanner';
import { TopBar } from './components/TopBar/TopBar';
import { Verify } from './components/Verify/Verify';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [signInError, setSignInError] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);
  const [isEnteringUserInfoSignUp, setIsEnteringUserInfoSignUp] = useState(false);
  const [isEnteringUserInfo, setIsEnteringUserInfo] = useState(false);
  const [hasChecked, setHasChecked] = useState(false)

  useEffect(() => {
    const checkLoggedIn = async () => {
      const user = localStorage.getItem("username");
      const pass = localStorage.getItem("password");
      const _id = localStorage.getItem("_id");
      if (user && pass && _id) {
        const logn = await login(user, pass);
        if (logn.data.active) {
          setIsSignedIn(true);
          setUsername(logn.data.username)
        }

      }
      setIsLoading(false);
    }
    if (!hasChecked) {
      setHasChecked(true)
      checkLoggedIn();
    }
  }, [])
  // TODO: add routing here properly
  return (<div>
    <TopBar isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} username={username} />
    {
      !isSignedIn && !isLoading && <SignIn username={username} setUsername={setUsername} password={password} setPassword={setPassword} email={email} setEmail={setEmail} signInError={signInError} setSignInError={setSignInError} signUpError={signUpError} setSignUpError={setSignUpError} isSignUpComplete={isSignUpComplete} setIsSignUpComplete={setIsSignUpComplete} isEnteringUserInfoSignUp={isEnteringUserInfoSignUp} setIsEnteringUserInfoSignUp={setIsEnteringUserInfoSignUp} setIsSignedIn={setIsSignedIn} setIsEnteringUserInfo={setIsEnteringUserInfo} isEnteringUserInfo={isEnteringUserInfo} />
    }
    {
      isSignedIn && !isLoading && window.location.pathname !== '/feeds/verify' && <EvScanner />
    }
    {
      isSignedIn && !isLoading && window.location.pathname === '/feeds/verify' && <Verify />
    }
    {
      isLoading && <div style={{ backgroundColor: "#485B79", height: '100vh', textAlign: "center", marginTop: 0 }}>
        <div style={{ paddingTop: "15rem" }}>
          <GridLoader />
        </div>
      </div>
    }
  </div>
  );
}

export default App;
